import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';

export default class SceneExporter {
    constructor() {
        this.threeManager =global.threeManager;
    }


    export() {
        let that = this;
        this.gltfExporter = new GLTFExporter();

        this.gltfExporter.parse(
            this.threeManager.scene,
            function (gltf) {
                const output = JSON.stringify(gltf, null, 2);
                that.saveString(output, '3D Scene Export.gltf');
            },
            function (error) {
                global.error('Error during GLTF export: ' + error);
                console.log(error);
            },
        );
    }



    saveString(text, filename) {
        this.save(new Blob([text], { type: 'text/plain' }), filename);
    }


    save(blob, filename) {
        const link = document.createElement('a');
        link.style.display = 'none';
        document.body.appendChild(link);

        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    }
}
