<!--
# Example usage:
<Toggle labelOn="Ja" labelOff="Nein" :bindTo="{ parent: 'design', key: 'curveHasThirdDimension' }" />
-->

<template>
  <div class="toggle_container">
    <label class="toggle_label">{{ label }}</label>
    <div class="toggle">
      <input type="radio" :id="UUID_left" v-model="$store.state[bindTo.parent][bindTo.key]" v-bind:value="false" @click="updateData" />
      <label :for="UUID_left">{{ labelOff }}</label>
      <input type="radio" :id="UUID_right" v-model="$store.state[bindTo.parent][bindTo.key]" v-bind:value="true" @click="updateData" />
      <label :for="UUID_right">{{ labelOn }}</label>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { getUID } from "@/utils/utils.js";

export default {
  props: ["onComplete", "bindTo", "labelOff", "labelOn", "label"],
  name: "Toggle",
  setup(props) {
    const store = useStore();

    // Generate unique ID for component
    let uid = getUID();
    let UUID_left = "switch_left-" + uid;
    let UUID_right = "switch_right-" + uid;

    // update VueX store data
    function updateData(event) {
      let booleanValue = event.target.value == "false" ? false: true;
      this.store.dispatch("updateState", {
        parent: props.bindTo.parent,
        key: props.bindTo.key,
        value: booleanValue,
      });

      // Trigger onComplete function (if present)
      if (props.onComplete != undefined) props.onComplete();
    }

    return { store, updateData, UUID_left, UUID_right };
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/theming.scss";
.toggle_label {
  user-select: none;
  cursor: pointer;
}
.toggle_container {
  font-size: 18px;
  flex: 1;
}
.toggle input {
  clip: rect(0, 0, 0, 0);
  height: 0px;
  width: 0px;
  border: 0;
  display: none;
  overflow: hidden;
}
.toggle input + label {
  width: 33%;
}
.toggle input:checked + label {
  @include theme(background-color, UI_toolBar_iconColor);
  @include theme(color, UI_window_lightBackground);
  width: 33%;
}
.toggle label {
  display: inline-block;
  width: 25%;
  background: transparent;
  @include theme(border, textColor, 1px solid);
  @include theme(color, textColor);
  text-align: center;
  font-size: 12px;
  padding: 10px 6px;
  transition: all 0.1s ease-in-out;
  user-select: none;
}
.toggle label:hover {
  cursor: pointer;
}
</style>