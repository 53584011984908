import { useStore } from "vuex";
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls';
import AnimationFrame from "@/utils/AnimationFrame.js";
import Stats from "@/utils/Stats";
import PostProcessing from '@/THREE/PostProcessing.js';

export default class THREE_Manager {
    constructor(args) {
        // VueX
        this.store = useStore();

        // Arguments & global variables
        this.sketchObject = args.sketchObject;
        this.parentContainer = document.getElementById(args.parentContainer);
        this.animationHandler = null;
        this.animationFrame = null;
        this.start = Date.now();
        this.clock = new THREE.Clock();

        // Triger THREE initialization
        this.init();

        // Start the animation loop
        this.startAnimationLoop();
    }




    // INIT ---------------------------------------------------------------------------------------------
    init() {
        global.log('Three.js revision ' + THREE.REVISION);

        // Check if tab is active or passive
        this.tabVisibilityEvent();

        // Init THREE.js
        this.init_renderer();
        this.init_scene();
        this.init_camera();
        this.init_controls();


        // PostProcessing
        if (this.store.state.three.usePostProcessing) {
            this.postProcessing = new PostProcessing({ threeManager: this });
        }

        // Add resize event
        window.addEventListener('resize', this.resize.bind(this));

        // Attach camera info event
        window.addEventListener("keyup", this.getCameraInfo.bind(this));

        // Initialize the actual sketch
        this.sketch = new this.sketchObject({ threeManager: this });

        // Trigge resize
        this.resize();

        // Store reference in VueX
        //this.store.dispatch("updateState", { parent: "three", key: "manager", value: this });
        global.threeManager = this;
    }


    // DESTROY ---------------------------------------------------------------------------------------------
    destroy() {
        this.stopAnimationLoop();
        this.sketch.destroy();
        this.stats = {};
    }



    // RENDERER ---------------------------------------------------------------------------------------------
    init_renderer() {
        // Create renderer
        this.renderer = new THREE.WebGLRenderer({
            antialias: this.store.state.three.antialiasing,
            powerPreference: 'high-performance',
            alpha: true,
        });
        this.renderer.setClearColor(new THREE.Color(this.store.state.colors.background));
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.domElement.id = 'threeWebGL';
        this.renderer.toneMapping = THREE.LinearToneMapping;

        // Check and apply shadow settings
        if (this.store.state.three.shadows) {
            this.renderer.shadowMap.enabled = true;
            this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        }

        // Check and apply retina settings
        if (this.store.state.three.retinaResolution) {
            this.renderer.setPixelRatio(window.devicePixelRatio);
            this.store.state.three.pixelRatio = window.devicePixelRatio;
        } else {
            this.renderer.setPixelRatio(1);
            this.store.state.three.pixelRatio = 1;
        }

        // Attach renderer to DOM
        this.parentContainer.appendChild(this.renderer.domElement);

    }



    // SCENE ---------------------------------------------------------------------------------------------
    init_scene() {
        this.scene = new THREE.Scene();
    }



    // CAMERA ---------------------------------------------------------------------------------------------
    init_camera() {
        this.camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 2000);
        this.camera.position.set(this.store.state.camera.position.x, this.store.state.camera.position.y, this.store.state.camera.position.z);
        this.camera.fov = this.store.state.camera.position.fov;
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
    }



    // CONTROLS ---------------------------------------------------------------------------------------------
    init_controls() {
        // TYPE: ORBIT CONTROLS
        if (this.store.state.camera.controlType == 'orbit') {
            this.controls = new OrbitControls(this.camera, this.renderer.domElement);
            this.controls.enableDamping = true;
            this.controls.enablePan = true;
            this.controls.dampingFactor = 0.1;
            this.controls.rotateSpeed = 0.7;
            this.controls.minDistance = 2;
            this.controls.maxDistance = 200;

            // Prevent controls from going beyond the ground
            if (this.store.state.camera.keepControlsAboveGround) {
                this.centerPosition = this.controls.target.clone();
                this.centerPosition.y = 0;
                this.groundPosition = this.camera.position.clone();
                this.groundPosition.y = 0;
                this.d = (this.centerPosition.distanceTo(this.groundPosition));
                this.origin = new THREE.Vector2(this.controls.target.y, 0);
                this.remote = new THREE.Vector2(0, this.d); // replace 0 with raycasted ground altitude
                this.angleRadians = Math.atan2(this.remote.y - this.origin.y, this.remote.x - this.origin.x);
                this.controls.maxPolarAngle = this.angleRadians;
            }

            this.controls.target.x = this.store.state.camera.target.x;
            this.controls.target.y = this.store.state.camera.target.y;
            this.controls.target.z = this.store.state.camera.target.z;
            this.controls.update();

            this.controls.addEventListener('start', () => {
                this.sketch.controlInteraction_started();
            });
            this.controls.addEventListener('end', () => {
                this.sketch.controlInteraction_ended();
            });
        }


        // TYPE: TRACKBALL CONTROLS
        if (this.store.state.camera.controlType == 'trackball') {
            this.controls = new TrackballControls(this.camera, this.renderer.domElement);

            this.controls.rotateSpeed = 1.0;
            this.controls.zoomSpeed = 1.2;
            this.controls.panSpeed = 0.8;
        }
    }




    // STATISTICS ---------------------------------------------------------------------------------------------
    init_stats() {
        if (this.store.state.enableStats) {
            this.stats = new Stats();
            let statsContainer = document.createElement("div");
            statsContainer.setAttribute("id", "Stats-output");
            statsContainer.appendChild(this.stats.dom);
            this.stats.dom.style.cssText = 'position:relative; display: inline-block; cursor: pointer;';
            document.getElementById("UI_stats_performance").appendChild(statsContainer);
        }
    }



    // RESIZE ---------------------------------------------------------------------------------------------
    resize() {
        if (!this.renderer) return;

        // Camera
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
        if (this.store.state.camera.controlType == 'trackball') {
            this.controls.handleResize();
        }

        // Renderer
        this.renderer.setSize(window.innerWidth, window.innerHeight);

        // Post Processing
        if (this.postProcessing) {
            this.postProcessing.resize();
        }

        // Exporter: Size
        this.store.dispatch("updateState", { parent: "export", key: "width", value: window.innerWidth });
        this.store.dispatch("updateState", { parent: "export", key: "height", value: window.innerHeight });
    }



    // ANIMATION LOOP: START ---------------------------------------------------------------------------------------------
    startAnimationLoop() {
        if (this.store.state.three.capFramerate) {
            let animFrame = new AnimationFrame(this.store.state.three.cappedFramerate, this.animate, this);
            animFrame.start();
        } else {
            this.animationHandler = this.animate.bind(this);
            this.animate();
        }
    }
    // ANIMATION LOOP: STOP ---------------------------------------------------------------------------------------------
    stopAnimationLoop() {
        cancelAnimationFrame(this.animationFrame);
        this.animationHandler = null;
    }



    // ANIMATION HANDLER ---------------------------------------------------------------------------------------------
    animate(delta, reference) {
        if (reference == undefined) {
            this.render();
            this.animationFrame = requestAnimationFrame(this.animationHandler);
        } else {
            reference.render();
        }
    }



    // RENDER LOOP LOGIC ---------------------------------------------------------------------------------------------
    render() {
        this.update();
        this.draw();
    }



    // UPDATE LOGIC ---------------------------------------------------------------------------------------------
    update() {
        // Stats
        if (this.stats != null) {
            this.stats.begin();
            // Send renderer-stats to state
            this.store.dispatch("updateStats", { stats: this.renderer.info });
        }

        // Clock
        if (this.clock) this.delta = this.clock.getDelta();

        // Controls
        if (this.controls) this.controls.update();

        // Sketch
        if (this.sketch) this.sketch.update();

        // Composer
        if (this.postProcessing) this.postProcessing.update();

        // FrameCount
        this.store.dispatch("animation_increaseFrameCount");
    }


    // DRAW LOGIC ---------------------------------------------------------------------------------------------
    draw() {
        // Render
        if (this.store.state.three.usePostProcessing) {
            if (this.postProcessing.composer) this.postProcessing.composer.render();
        } else {
            if (this.renderer) this.renderer.render(this.scene, this.camera);
        }

        // Stats
        if (this.stats != null) this.stats.end();
    }



    // CHECK IF TAB IS ACTIVE  ---------------------------------------------------------------------------------------------
    tabVisibilityEvent() {
        document.addEventListener('visibilitychange', () => {
            if (document.hidden) {
                this.tabActive = false;
                this.clock.stop();
                // console.log('Page is hidden from user view');
            } else {
                this.tabActive = true;
                this.clock.start();
                // console.log('Page is in user view');
            }
        });
    }




    // GET CAMERA INFORMATION ON PRESSING THE C-KEY  ---------------------------------------------------------------------------------------------
    getCameraInfo(e) {
        // C > Show camera position and rotation
        if (e.keyCode == 67) {
            let posString = "position: { x: ";
            posString += this.camera.position.x;
            posString += ", y: ";
            posString += this.camera.position.y;
            posString += ", z: ";
            posString += this.camera.position.z;
            posString += ", fov: ";
            posString += this.camera.fov;
            posString += " },";
            console.log(posString);

            let rotString = "rotation: { x: ";
            rotString += this.camera.rotation.x;
            rotString += ", y: ";
            rotString += this.camera.rotation.y;
            rotString += ", z: ";
            rotString += this.camera.rotation.z;
            rotString += " },";
            console.log(rotString);

            let targetString = "target: { x: ";
            targetString += this.controls.target.x;
            targetString += ", y: ";
            targetString += this.controls.target.y;
            targetString += ", z: ";
            targetString += this.controls.target.z;
            targetString += " },";
            console.log(targetString);
        }
    }

}