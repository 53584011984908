import { Vector3 } from 'three';
import { useStore } from "vuex";
import { watch } from "@vue/runtime-dom";
import { random } from '@/utils/utils.js';

export default class DATA_Manager {
    constructor(args) {
        // VueX
        this.store = useStore();

        // Initialize
        this.init();
    }




    // INIT ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    init() {
        this.randomPositions = {};

        // Load position data
        this.load_fileFromServer(this.store.getters.getPublicPath + 'data/2022-06-22-visualization.json').then((fileContents) => {
            global.log('Visualization JSON object received successfully')
            this.store.dispatch("updateState", { parent: "data", key: "objectPositions", value: JSON.parse(fileContents) });
        });

        // Data Index (codes)
        this.load_fileFromServer(this.store.getters.getPublicPath + 'data/2022-06-22-codes.tsv').then((fileContents) => {
            let parsedData = this.parse_indexFile(fileContents);
            parsedData['Region'] = { 1: 'Europe', 2: 'North America', 3: 'Asia Pacific', 4: 'Other' };
            parsedData['Business size'] = { 1: 'Less than US$10 million', 2: 'US$11 - 499 million (b)', 3: 'US$500 million - 2.5 billion' };
            this.store.dispatch("updateState", { parent: "data", key: "index", value: parsedData });
        });

        // Data
        this.load_fileFromServer(this.store.getters.getPublicPath + 'data/2022-06-22-dataset.tsv').then((fileContents) => {
            this.store.dispatch("updateState", { parent: "data", key: "questions", value: this.parse_dataFile(fileContents) });
        });


        // Watch if all data has been loaded
        watch(() => [this.store.state.data.objectPositions, this.store.state.data.columns, this.store.state.data.index, this.store.state.data.questions], () => {
            // If the data assets are populated, set the initial active question
            if (this.store.state.data.objectPositions != null && this.store.state.data.columns != null && this.store.state.data.index != null && this.store.state.data.questions != null) {
                this.store.dispatch("updateState", { parent: "data", key: "activeQuestionIndex", value: 0 });
                this.store.dispatch("updateState", { parent: "data", key: "activeQuestionSegmentIndex", value: 0 });
                this.store.dispatch("updateState", { parent: "data", key: "activeQuestion", value: Object.values(this.store.state.data.questions)[this.store.state.data.activeQuestionIndex] });
            }
        });
    }

    // DESTROY ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    destroy() {
    }





    // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    // METHODS
    // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------


    // PARSE THE INDEX FILE ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    parse_indexFile(rawData) {
        // Split raw data into an array which contains the individual lines as strings
        let lines = rawData.split('\n');

        // Initialized the data object
        let parsedData = { Answer: {} };

        // This array will store all collected data while looping over the raw data
        // We reset it, once we get to a new block of content (indicated by any line that is not a number, thus not referencing index data)
        // let collectedData = [];
        let collectedData = {};

        // Parse all lines
        for (let index in lines) {
            // Split raw data into an array which contains the individual lines as strings
            let lineContent = lines[index].split('\t');

            // If the line is not a number (i.e. not an index reference): Create a new entry in the "parsedData" object
            if (isNaN(lineContent[0])) {
                // collectedData = [];
                collectedData = {};

                // Add new key to object
                if (lineContent[0].match(/Question \d+$/)) {
                    let questionIndex = parseFloat(lineContent[0].match(/\d+/)); // extract number from String

                    // For questions
                    parsedData.Answer['Question ' + questionIndex] = collectedData;
                } else {
                    // Other datasets like sector, size, job title, etc.
                    parsedData[lineContent[0]] = collectedData;
                }

            } else {
                // If the line starts with a number: Add to collected data array
                collectedData[lineContent[0]] = lineContent[1].replace('\r', '');
                //collectedData.push(lineContent[1].replace('\r', ''));
            }
        }

        return parsedData;
    }










    // PARSE THE DATA FILE ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    parse_dataFile(rawData) {
        // Split raw data into an array which contains the individual lines as strings
        let lines = rawData.split('\n');

        // Initialize the data object
        let parsedData = {};

        // This array will store all collected data while looping over the raw data
        // We reset it, once we get to a new block of content (indicated by any line that is not a number, thus not referencing index data)
        let collectedData = [];

        // This array holds are identifiers set in the first line of the dataset
        let columnIndex = [];

        //
        let segmentedQuestionData = [];
        let currentSegmentedObject = null;

        // Parse all lines
        let index = 0;
        while (lines[index] != undefined) {
            // Split raw data into an array which contains the individual lines as strings
            let lineContent = lines[index].split('\t');


            // >>> DATA VARIATION: Column index (which has to be exist on line 1)
            // This line contains column-specific information which connects the data to the index file
            if (index == 0) {
                // Populate the columnIndex array
                for (let i = 0; i < lineContent.length; i++) {
                    columnIndex.push(this.cleanString(lineContent[i]));
                }

                // Push to state
                this.store.dispatch("updateState", { parent: "data", key: "columns", value: columnIndex });
            }


            // >>> DATA VARIATION: Basic questions (no sub-answers)
            else if (lineContent[0].match(/Question \d+$/)) {
                let question = this.cleanString(lineContent[0]);
                index++; // increase index by one to grab the answer from the next line
                let answer = this.cleanString(lines[index]);
                let questionIndex = parseFloat(lineContent[0].match(/\d+/)); // extract number from String

                this.cleanDataArray(collectedData); // make data array into array of numbers and remove \t & \r
                collectedData = [];

                // Add new key to object
                let questionObject = {
                    questionIndex: questionIndex,
                    question: question,
                    answer: answer,
                    data: collectedData
                };
                //parsedData.push(questionObject);
                parsedData['Question ' + questionIndex] = questionObject;

                // Mark as non-staged question
                currentSegmentedObject = null;
            }


            // >>> DATA VARIATION: Staged questions (with sub-answers)
            else if (lineContent[0].match(/Question \d+([a-z])/i)) {
                let question = this.cleanString(lineContent[0]);
                let questionIndex = parseFloat(lineContent[0].match(/\d+/)); // extract number from String
                index++; // increase index by one to grab the answer from the next line
                let answer = this.cleanString(lines[index]);
                index++; // increase by one to grab the secondary answer to this staged question
                let subAnswer = this.cleanString(lines[index]);

                this.cleanDataArray(collectedData); // make data array into array of numbers and remove \t & \r
                collectedData = [];

                // If "currentSegmentedObject" is marked as null, that means we can initialize a new segmented question
                if (currentSegmentedObject == null) {
                    segmentedQuestionData = [];

                    // Build the parent object & push to dataArray
                    let questionObject = {
                        questionIndex: questionIndex,
                        question: 'Question ' + questionIndex,
                        answer: answer,
                        segments: segmentedQuestionData,
                    };
                    parsedData['Question ' + questionIndex] = questionObject;
                    // parsedData.push(questionObject);

                    // Mark this as a new segmentedObject
                    currentSegmentedObject = questionIndex;
                }

                // Build the child object and push into "questionObject" array
                // This will continue until "currentSegmentedObject" is marked as null again
                let questionObject = {
                    questionIndex: questionIndex,
                    question: question,
                    answer: answer,
                    subAnswer: subAnswer,
                    data: collectedData
                };
                segmentedQuestionData.push(questionObject)
            }


            // >>> DATA VARIATION: A line with actual data
            else {
                let dataSet = {}

                // Populate the object by the data's names as defined in the columnIndex
                for (let i = 0; i < columnIndex.length; i++) {
                    // Mark lines which are empty as -1
                    if (lineContent[i] == '')
                        dataSet[columnIndex[i]] = -1;
                    else
                        // Normal data
                        dataSet[columnIndex[i]] = parseFloat(lineContent[i]);
                }

                // SPECIAL CASE: Segment region, based on location
                // ---------------------------------------------------------------------------------------------------------
                let regionIndex = lineContent[3];
                if (regionIndex == 3 || regionIndex == 4 || regionIndex == 7) { dataSet['Region'] = 1 } // europe
                if (regionIndex == 8) { dataSet['Region'] = 2 } // north america
                if (regionIndex == 1 || regionIndex == 2 || regionIndex == 5 || regionIndex == 6) { dataSet['Region'] = 3 } // asia pacific
                if (regionIndex == 9) { dataSet['Region'] = 4 } // other
                // ---------------------------------------------------------------------------------------------------------

                // SPECIAL CASE: Segment businessSector, based on sector
                // ---------------------------------------------------------------------------------------------------------
                let sectorIndex = lineContent[2];
                if (sectorIndex == 1) { dataSet['Business size'] = 1 } // small
                if (sectorIndex == 2) { dataSet['Business size'] = 2 } // medium
                if (sectorIndex == 3 || sectorIndex == 4) { dataSet['Business size'] = 3 } // large
                // if (sectorIndex == 1 || sectorIndex == 2) { dataSet['Business size'] = 1 } // small
                // if (sectorIndex == 3 || sectorIndex == 4 || sectorIndex == 5) { dataSet['Business size'] = 2 } // medium
                // if (sectorIndex == 6 || sectorIndex == 7 || sectorIndex == 8 || sectorIndex == 9 || sectorIndex == 10) { dataSet['Business size'] = 3 } // large
                // ---------------------------------------------------------------------------------------------------------


                // Push data into the array
                collectedData.push(dataSet);
            }

            // Increment index
            index++;
        }


        return parsedData;
    }
    // Remove all \t and \r occurrences from a string
    cleanString(stringInput) {
        return stringInput.replace(/\t/g, '').replace(/\r/g, '');
    }
    // Remove all \t and \r occurrences from an array by turning it into actual numbers
    cleanDataArray(dataArray) {
        for (let index in dataArray) {
            for (let cols in dataArray[index]) {
                // Turn string into a number
                dataArray[index][cols] = parseFloat(dataArray[index][cols])

                // If the result is not a number (i.e. empty line) mark via -1
                if (isNaN(dataArray[index][cols])) dataArray[index][cols] = -1;
            }
        }
    }









    // LOAD FILE FROM SERVER VIA XHR REQUEST ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    load_fileFromServer(dataFile) {
        return new Promise((resolve) => {
            global.log('Requesting ' + dataFile + ' from server...');

            let xhr = new XMLHttpRequest();
            xhr.onreadystatechange = () => {
                if (xhr.readyState == 4 && xhr.status >= 200 && xhr.status < 300) {
                    global.log(dataFile + ' received successfully.');
                    resolve(xhr.responseText);
                }
            };
            xhr.open('GET', dataFile);
            xhr.send();

            // Error handling
            xhr.addEventListener('error', handleEvent);
            function handleEvent(e) {
                console.error(e);
                global.error(e);
            }
        });
    };






    // DETERMINE AND RETURN POSITION DATA ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    getPositionData(dataToVisualize) {
        let positionData = this.store.state.data.objectPositions[dataToVisualize];
        if (typeof positionData == 'undefined') return;

        // Determine the type of position data to create
        let type = positionData.type;

        // Determine the amount of elements that we need to create points for
        let dataPoints = this.store.state.data.index[dataToVisualize];
        let amountOfPoints = Object.keys(dataPoints).length;

        // Check if the contents of the array contains nested data (the Answer object is nested)
        // If that is the case, determine the amount of points to create by accessing the nested data, based on the active question
        if (typeof Object.values(dataPoints)[0] == 'object') {
            let questionID = 'Question ' + this.store.state.data.activeQuestion.questionIndex;
            amountOfPoints = Object.keys(this.store.state.data.index.Answer[questionID]).length;
        }

        // Initialize positions array
        let positions = [];

        // >>>> DYNAMIC DISTRIBUTION OF POINTS ON ONE AXIS, BASED ON VARIANCE VALUE
        if (type == 'dynamic') {
            for (let i = 0; i < amountOfPoints; i++) {
                let point;

                // Vertical variance
                if (positionData.direction == 'vertical') {
                    point = new Vector3(positionData.initialPosition.x, positionData.initialPosition.y + (i * positionData.variance), positionData.initialPosition.z);
                }
                // Horizontal variance
                if (positionData.direction == 'horizontal') {
                    point = new Vector3(positionData.initialPosition.x + (i * positionData.variance), positionData.initialPosition.y, positionData.initialPosition.z);
                }

                positions.push([point, -1])
            }
        }

        // >>>> STATIC DISTRIBUTION OF POINTS, BASED ON DATA IN JSON ARRAY
        if (type == 'static') {
            for (let i = 0; i < positionData.positions.length; i++) {
                let point = new Vector3(positionData.positions[i][0], positionData.positions[i][1], positionData.positions[i][2]);
                positions.push([point, -1]);
            }
        }

        // >>>> RANDOM DISTRIBUTION OF POINTS, BASED ON 3D-RANGE 
        if (type == 'random') {
            // If there have not been any random positions created for this element, create those and push to "randomPositions" object
            if (this.randomPositions[dataToVisualize] == undefined) {
                for (let i = 0; i < amountOfPoints; i++) {
                    let point = new Vector3(random(positionData.from.x, positionData.to.x), random(positionData.from.y, positionData.to.y), random(positionData.from.z, positionData.to.z));
                    positions.push([point, -1]);
                }
                this.randomPositions[dataToVisualize] = positions;
            } else {
                // If the positions have already been created, retrieve them from the "randomPositions" object
                for (let i = 0; i < this.randomPositions[dataToVisualize].length; i++) {
                    positions.push(this.randomPositions[dataToVisualize][i]);
                }
            }
        }

        // >>>> SEMI-RANDOM DISTRIBUTION OF POINTS, A COMBINATION OF RANDOM POSITIONS AND A VARIANCE RANGE
        if (type == 'semiRandom') {
            let variations = { x: { position: null, variance: null }, y: { position: null, variance: null }, z: { position: null, variance: null } };

            // Check each positionDataElement ("from") if it is a simple number or an object. Objects contain varied information, numbers simple random instructions
            function checkVariationType(positionElementData, pushToVariation) {
                if (typeof positionElementData != 'number') {
                    pushToVariation.position = positionElementData.position;
                    pushToVariation.variance = positionElementData.variance;
                }
            }
            checkVariationType(positionData.from.x, variations.x);
            checkVariationType(positionData.from.y, variations.y);
            checkVariationType(positionData.from.z, variations.z);

            // If there have not been any random positions created for this element, create those and push to "randomPositions" object
            if (this.randomPositions[dataToVisualize] == undefined) {
                // Distribute points
                for (let i = 0; i < amountOfPoints; i++) {
                    let point = getSemiRandomPoint();
                    positions.push([point, -1]);
                }
                this.randomPositions[dataToVisualize] = positions;
            } else {
                // If the positions have already been created, retrieve them from the "randomPositions" object
                for (let i = 0; i < this.randomPositions[dataToVisualize].length; i++) {
                    positions.push(this.randomPositions[dataToVisualize][i]);
                }
            }


            function getSemiRandomPoint() {
                let point = new Vector3();

                if (variations.x.position == null) {
                    point.x = random(positionData.from.x, positionData.to.x);
                } else {
                    point.x = variations.x.position;
                    variations.x.position += variations.x.variance;
                }

                if (variations.y.position == null) {
                    point.y = random(positionData.from.y, positionData.to.y);
                } else {
                    point.y = variations.y.position;
                    variations.y.position += variations.y.variance;
                }

                if (variations.z.position == null) {
                    point.z = random(positionData.from.z, positionData.to.z);
                } else {
                    point.z = variations.z.position;
                    variations.z.position += variations.z.variance;
                }

                return point;
            }
        }

        // Update positions object with computed positions
        let positionObject = this.store.state.data.objectPositions;
        positionObject[dataToVisualize]["computedPositions"] = positions;
        this.store.dispatch("updateState", { parent: "data", key: "objectPositions", value: positionObject });

        // Finally, return position array
        return positions;
    }

}