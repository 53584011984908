import { markRaw } from 'vue';
import { createStore } from 'vuex'

export default createStore({
  state: {
    version: process.env.VUE_APP_VERSION || '0',
    publicPath: process.env.BASE_URL,
    enableStats: true,
    frameCount: 0,
    three: {
      antialiasing: true,
      retinaResolution: true,
      pixelRatio: 1,
      shadows: true,
      capFramerate: false,
      cappedFramerate: 30,
      usePostProcessing: false,
    },
    camera: {
      controlType: 'orbit', // orbit || trackball
      position: { x: -2.7041156583370967, y: 0.8110590051848439, z: 52.877293083721405, fov: 40 },
      rotation: { x: -6.117328865684608e-17, y: 0.08380326423131029, z: 5.120522797831428e-18 },
      target: { x: -7.125168289916023, y: 0.8110590051848406, z: 0.2457141363529502 },

      // position: { x: 0, y: 0, z: 20, fov: 40 },
      // rotation: { x: 0, y: 0, z: 0 },
      // target: { x: 0, y: 0, z: 0 },
      keepControlsAboveGround: false,
    },
    colors: {
      flatDesign: false,
      flatLines: true,
      background: "#1f2023",
      primary: '#eeeeee',
      highlightColor: '#96d0d3',
      inactiveColor: '#a1a1a1',
    },
    data: {
      activeQuestionIndex: null,
      activeQuestionSegmentIndex: null,
      activeQuestion: null,
      columns: null,
      index: null,
      questions: null,
      objectPositions: null,
      filterObjects: [],
      filterAmount: 0,
    },
    UI: {
      theme: "dark",
      visible: false,
      windows: [],
      mountedWindows: [],
      windowZIndex: 1000,
      questionSelectorActive: false,
      console: {
        content: "",
        sticky: "",
      },
      performance: {
        stats: {
          window: null,
          geometries: 0,
          renderCalls: 0,
          frame: 0,
          triangles: 0,
        }
      },
    },
    export: {
      imageFormat: 'PNG',
      videoFormat: 'WEBM',
      transparentBackground: false,
      width: null,
      height: null,
    }
  },
  getters: {
    appVersion: (state) => {
      return state.version.replace(/["]+/g, '')
    },
    themeHasChanged: (state) => {
      return state.UI.theme;
    },
    getPublicPath: (state) => {
      return state.publicPath;
    }
  },
  mutations: {
    ANIMATION_INCREASE_FRAMECOUNT(state) {
      state.frameCount++;
    },
    CONSOLE_LOG(state, { log }) {
      state.UI.console.content += log + "<br/>";
    },
    CONSOLE_ERROR(state, { log }) {
      state.UI.console.content += '<span style="color: #ff0000 !important;">' + log + '</span>' + '<br/>';
    },
    CONSOLE_STICKY(state, { log }) {
      state.UI.console.sticky = log;
    },
    UPDATE_STATE(state, { parent, key, value }) {
      state[parent][key] = value;
    },
    UI_REGISTER_WINDOW(state, { window }) {
      state["UI"]["windows"].push(markRaw(window));
    },
    UI_MOUNT_WINDOW(state, { window }) {
      let newArray = state.UI.mountedWindows.concat([{ window: window, processed: false }]);
      state.UI.mountedWindows = newArray;
    },
    UI_TOGGLE_VISIBILITY(state) {
      state["UI"]["visible"] = !state["UI"]["visible"];
    },
    UPDATE_STATS(state, { stats }) {
      state.UI.performance.stats.geometries = stats.memory.geometries;
      state.UI.performance.stats.renderCalls = stats.render.calls;
      state.UI.performance.stats.frame = stats.render.frame;
      state.UI.performance.stats.triangles = stats.render.triangles;
    },
    ADD_FILTER_OBJECT(state, { ID }) {
      if (state.data.filterObjects.includes(ID)) {
        // If the array already contains the ID, remove it again
        state.data.filterObjects = state.data.filterObjects.filter((item) => { return item !== ID });
      } else {
        // If the array does not contain the ID, add it
        state.data.filterObjects.push(ID);
      }
    },
    ADD_CURVE_TO_ACTIVEQUESTION(state, { elementIndex, curve }) {
      state.data.activeQuestion.data[elementIndex]['curve'] = curve;
    }
  },
  actions: {
    animation_increaseFrameCount({ commit }) {
      commit('ANIMATION_INCREASE_FRAMECOUNT');
    },
    consoleLog({ commit }, { log }) {
      commit('CONSOLE_LOG', { log });
    },
    consoleError({ commit }, { log }) {
      commit('CONSOLE_ERROR', { log });
    },
    consoleSticky({ commit }, { log }) {
      commit('CONSOLE_STICKY', { log });
    },
    updateState({ commit }, { parent, key, value }) {
      commit('UPDATE_STATE', { parent, key, value });
    },
    UI_registerWindow({ commit }, { window }) {
      commit('UI_REGISTER_WINDOW', { window });
    },
    UI_mountWindow({ commit }, { window }) {
      commit('UI_MOUNT_WINDOW', { window });
    },
    UI_toggleVisibility({ commit }) {
      commit('UI_TOGGLE_VISIBILITY');
    },
    updateStats({ commit }, { stats }) {
      commit('UPDATE_STATS', { stats });
    },
    data_addFilterObject({ commit }, { ID }) {
      commit('ADD_FILTER_OBJECT', { ID });
    },
    data_addCurveToActiveQuestion({ commit }, { elementIndex, curve }) {
      commit('ADD_CURVE_TO_ACTIVEQUESTION', { elementIndex, curve })
    }
  },
  modules: {
  }
})
