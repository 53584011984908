// THREE
import * as THREE from 'three';

// Vue
import { watch } from 'vue';

export default class Interface {
    constructor(args) {
        // VueX
        this.store = global.storeReference;

        // Three.js reference
        this.three = args.threeManager;

        // Designsystem reference
        this.designSystem = args.designSystem;

        // Init
        this.init();
    }


    // INIT ---------------------------------------------------------------------------------------------

    init() {
        // Clear label container and grab DOM-reference
        this.labelContainer = document.querySelector('#labels');
        this.labelContainer.innerHTML = '';
        this.labeledObjects = [];
        this.raycastObjects = [];
        this.activatedByHovering = false;
        this.labelIsHovered = false;
        this.controlsEngagedInInteraction = false;

        // Init raycaster
        this.initRaycaster();

        // Add watcher functions
        this.watchForData();
    }


    update() {
        this.updateLabelPositions();
        this.raycastCheckForIntersection();
    }



    // METHODS ---------------------------------------------------------------------------------------------




    // LABELS: REACT TO INTERACTIVITY ---------------------------------------------------------------------------------------------
    addLabelInteractivity() {
        this.activeMesh = null;
        this.pointerEventsTimer = null;

        // Attach event listeners to each label
        this.labeledObjects.forEach((meshInfo) => {
            let { elem, mesh } = meshInfo;
            elem.addEventListener('mouseup', (e) => { this.labelClick(e, mesh) });
            elem.addEventListener('mouseover', (e) => { this.labelMouseOver(e, mesh) });
            elem.addEventListener('mouseleave', (e) => { this.labelMouseLeave(e, mesh) });
        });
    }
    labelClick(e, mesh) {
        if (this.INTERSECTED == null) {
            if (!this.activatedByHovering) {
                this.store.dispatch("data_addFilterObject", { ID: mesh.userData.ID });
            }
            this.activatedByHovering = false;
        }
    }
    labelMouseOver(e, mesh) {
        // Don't raycast when orbitControls are currently engaged
        if (this.controlsEngagedInInteraction) return;

        this.labelIsHovered = true;
        this.activeMesh = mesh.userData.ID;
        mesh.userData.elem.classList.add('hovered');
        mesh.material.color = new THREE.Color(this.store.state.colors.highlightColor);
        if (!this.store.state.colors.flatDesign)
            mesh.material.emissive = new THREE.Color(this.store.state.colors.highlightColor);


        // Special case: If hovered and we have no active filters: Mark the hovered object as active
        if (this.store.state.data.filterObjects.length == 0) {
            this.activatedByHovering = true;
            this.store.dispatch("data_addFilterObject", { ID: mesh.userData.ID });
            this.designSystem.visualizeSingleDatapoint();
            this.INTERSECTED = mesh;
        }
    }
    labelMouseLeave(e, mesh) {
        // Reset color of mesh and DOM element, when it is not part of the active filtered objects array
        if (!this.store.state.data.filterObjects.includes(mesh.userData.ID)) {
            if (this.INTERSECTED != null) {
                // Check if the object we are hovering to is not the mesh object which fits to our label
                if (this.INTERSECTED.userData.ID != mesh.userData.ID) {
                    mesh.material.color = new THREE.Color(this.store.state.colors.primary);
                    if (!this.store.state.colors.flatDesign)
                        mesh.material.emissive = new THREE.Color('#000000');

                    mesh.userData.elem.classList.remove('hovered');
                    this.activeMesh = null;
                }
            } else {
                // If there is no intersection from the raycaster, remove the color as well
                mesh.material.color = new THREE.Color(this.store.state.colors.primary);
                if (!this.store.state.colors.flatDesign)
                    mesh.material.emissive = new THREE.Color('#000000');

                mesh.userData.elem.classList.remove('hovered');
                this.activeMesh = null;
            }
        }

        this.activeMesh = null;
        this.labelIsHovered = false;
    }
    // Momentarily disable pointer events on each label as orbitControls are active
    disableLabels() {
        clearTimeout(this.pointerEventsTimer);
        this.controlsEngagedInInteraction = true;
        this.labeledObjects.forEach((meshInfo) => {
            let { elem, mesh } = meshInfo;
            if (elem.style.pointerEvents != 'none') {
                elem.style.pointerEvents = 'none';
            }
        });
    }

    // Re-enable pointer-events after orbitControls interaction has ended
    enableLabels() {
        clearTimeout(this.pointerEventsTimer);
        this.pointerEventsTimer = setTimeout(() => {
            this.labeledObjects.forEach((meshInfo) => {
                let { elem, mesh } = meshInfo;
                if (elem.style.pointerEvents == 'none') {
                    elem.style.pointerEvents = '';
                }
            });
            this.controlsEngagedInInteraction = false;
        }, 100);
    }




    // LABELS: UPDATE POSITION ---------------------------------------------------------------------------------------------
    updateLabelPositions() {
        if (this.labeledObjects) {
            if (this.labeledObjects.length > 0) {
                const tempV = new THREE.Vector3();
                // const raycaster = new THREE.Raycaster();


                this.labeledObjects.forEach((meshInfo, ndx) => {
                    let { mesh, elem } = meshInfo;

                    // get the position of the center of the mesh
                    mesh.updateWorldMatrix(true, false);
                    mesh.getWorldPosition(tempV);

                    // get the normalized screen coordinate of that position
                    // x and y will be in the -1 to +1 range with x = -1 being
                    // on the left and y = -1 being on the bottom
                    tempV.project(this.three.camera);

                    // // ask the raycaster for all the objects that intersect
                    // // from the eye toward this object's position
                    // raycaster.setFromCamera(tempV, this.three.camera);
                    // const intersectedObjects = raycaster.intersectObjects(this.three.scene.children);
                    // // We're visible if the first intersection is this object.
                    // const show = intersectedObjects.length && mesh === intersectedObjects[0].object;

                    // if (!show || Math.abs(tempV.z) > 1) {
                    //     // hide the label
                    //     elem.style.display = 'none';
                    // } else {
                    //     // unhide the label
                    //     elem.style.display = '';
                    // }

                    // convert the normalized position to CSS coordinates
                    let x = (tempV.x * .5 + .5) * this.three.renderer.domElement.clientWidth;
                    let y = (tempV.y * -.5 + .5) * this.three.renderer.domElement.clientHeight;
                    // let x = (tempV.x + 1) / 2 * this.three.renderer.domElement.clientWidth;
                    // let y = -(tempV.y - 1) / 2 * this.three.renderer.domElement.clientHeight;

                    // move the elem to that position
                    x += 10;
                    elem.style.left = x + 'px';
                    elem.style.top = -9 + y + 'px';
                    // elem.style.transform = `translate(-50%, -50%) translate(${x}px,${y}px)`;

                    // set the zIndex for sorting
                    elem.style.zIndex = (-tempV.z * .5 + .5) * 100000 | 0;
                });
            }
        }
    }







    // REACTIVITY: WATCH THE "FILTEROBJECTS" ARRAY ---------------------------------------------------------------------------------------------
    watchForData() {
        // Watch for changes in filtered objects
        watch(() => this.store.state.data.filterObjects, () => {
            this.colorizeFilterMeshes();
        },
            { deep: true });
    }

    // COLORIZE ALL MESHES THAT INCLUDED IN THE "FILTEROBJECTS" ARRAY ---------------------------------------------------------------------------------------------
    colorizeFilterMeshes() {
        // Traverse the entire scene graph
        this.three.scene.traverse((element) => {
            // Check if the current element is of type Mesh and matches any of the IDs in the "filterObjects" array
            if (element instanceof THREE.Mesh && this.store.state.data.filterObjects.includes(element.userData.ID)) {
                // If both criteria are met, color in the mesh
                element.userData.elem.classList.add('hovered');
                element.material.color = new THREE.Color(this.store.state.colors.highlightColor);

                if (!this.store.state.colors.flatDesign)
                    element.material.emissive = new THREE.Color(this.store.state.colors.highlightColor);
            }
        });
    }







    // INITIALIZE RAYCASTER ---------------------------------------------------------------------------------------------
    initRaycaster() {
        this.raycaster = new THREE.Raycaster();
        this.pointer = new THREE.Vector2();
        this.mouse = new THREE.Vector2();
        this.INTERSECTED = null;

        document.addEventListener('mousemove', (e) => { this.onMouseMove(e) });
        document.addEventListener('mouseup', (e) => { this.onMouseUp(e) });
    }

    // REACT TO MOUSE MOVEMENT ---------------------------------------------------------------------------------------------
    onMouseMove(e) {
        // Three.js space
        this.pointer.x = (e.clientX / window.innerWidth) * 2 - 1;
        this.pointer.y = - (e.clientY / window.innerHeight) * 2 + 1;

        // DOM space
        this.mouse.x = e.clientX;
        this.mouse.y = e.clientY;
    }
    onMouseUp(e) {
        if (this.INTERSECTED) {
            if (!this.activatedByHovering) {
                this.store.dispatch("data_addFilterObject", { ID: this.INTERSECTED.userData.ID });
            }
            this.activatedByHovering = false;
        }
    }



    // RAYCASRER: CHECK FOR INTERSECTIONS ---------------------------------------------------------------------------------------------
    raycastCheckForIntersection() {
        // Don't raycast when orbitControls are currently engaged
        if (this.controlsEngagedInInteraction) return;

        if (this.raycaster) {
            this.raycaster.setFromCamera(this.pointer, this.three.camera);
            const intersects = this.raycaster.intersectObjects(this.raycastObjects, false);

            if (intersects.length > 0) {
                // Change cursor to pointer style
                document.body.style.cursor = 'pointer';

                if (this.INTERSECTED != intersects[0].object) {
                    // Reference to the intersected element
                    this.INTERSECTED = intersects[0].object;

                    // Colorize mesh and DOM label
                    this.INTERSECTED.userData.elem.classList.add('hovered');
                    this.INTERSECTED.material.color = new THREE.Color(this.store.state.colors.highlightColor);
                    if (!this.store.state.colors.flatDesign)
                        this.INTERSECTED.material.emissive = new THREE.Color(this.store.state.colors.highlightColor);

                    this.activeMesh = null;

                    // Special case: If hovered and we have no active filters: Mark the hovered object as active
                    if (this.store.state.data.filterObjects.length == 0) {
                        this.activatedByHovering = true;
                        this.store.dispatch("data_addFilterObject", { ID: this.INTERSECTED.userData.ID });
                        this.designSystem.visualizeSingleDatapoint();
                    }
                }
            } else {
                if (this.INTERSECTED && !this.labelIsHovered) {
                    // Set cursor to default arrow
                    document.body.style.cursor = 'default';

                    // Special case: If hovering and no elements selected: Remove intersected object from filterPool 
                    if (this.activatedByHovering) {
                        this.store.dispatch("data_addFilterObject", { ID: this.INTERSECTED.userData.ID });
                        this.activatedByHovering = false;
                    }

                    // Reset color of mesh and DOM element
                    if (!this.store.state.data.filterObjects.includes(this.INTERSECTED.userData.ID) && this.INTERSECTED.userData.ID != this.activeMesh) {
                        this.INTERSECTED.material.color = new THREE.Color(this.store.state.colors.primary);
                        if (!this.store.state.colors.flatDesign)
                            this.INTERSECTED.material.emissive = new THREE.Color('#000000');

                        this.INTERSECTED.userData.elem.classList.remove('hovered');
                    }

                    // Mark intersected as not present
                    this.INTERSECTED = null;
                }

            }


        }
    }





}