import { Vector2, Color } from 'three';

export default class ImageExporter {
    constructor() {
        this.threeManager = global.threeManager;

        this.fileFormat = (global.storeReference.state.export.imageFormat == 'PNG' ? 'image/png' : 'image/jpeg');
        this.transparentBackground = global.storeReference.state.export.transparentBackground;
        if (this.fileFormat == 'image/jpeg') this.transparentBackground = false;

        this.renderedSlices = [];
        this.originalRendererSize = new Vector2();
        this.targetSize = new Vector2();
        this.sliceSize = new Vector2();

        // Determine amount of slices
        let THREE_canvas = document.getElementById("threeWebGL");
        let scaleDimensions = {
            width: Math.ceil(global.storeReference.state.export.width / THREE_canvas.width),
            height: Math.ceil(global.storeReference.state.export.height / THREE_canvas.height),
        };
        this.amountOfSlices = scaleDimensions.width > scaleDimensions.height ? scaleDimensions.width : scaleDimensions.height;
    }



    export() {
        // Set background to transparent if option is active
        if (this.transparentBackground) {
            this.threeManager.renderer.setClearColor(new Color(global.storeReference.state.colors.background), 0);
        }

        // Set pixel ratio to 1 (so that we have parity between retina and non-retina screens)
        this.threeManager.renderer.setPixelRatio(1);

        // Create the individual slices and store them to an array
        this.createRenderSlices().then(() => {
            // Re-assemble slices into one large image
            this.stichSlices().then(highResCanvas => {
                // Make download available
                let link = document.createElement('a');
                link.download = 'High Resolution Export.' + (this.fileFormat == 'image/png' ? 'png' : 'jpg');
                let dataURL = highResCanvas.toDataURL(this.fileFormat);
                link.href = dataURL;
                link.click();

                // Re-set pixelRatio to renderer default
                this.threeManager.renderer.setPixelRatio(global.storeReference.state.three.pixelRatio);

                // Set background back to its normal color
                if (this.transparentBackground) {
                    this.threeManager.renderer.setClearColor(new Color(global.storeReference.state.colors.background), 1);
                }

                // Resize browser and continue animation loop
                this.resizeRenderer(this.originalRendererSize.x, this.originalRendererSize.y);
                this.threeManager.camera.setViewOffset(this.originalRendererSize.x, this.originalRendererSize.y, 0, 0, this.originalRendererSize.x, this.originalRendererSize.y)
                this.threeManager.startAnimationLoop();
            });
        });
    }




    createRenderSlices() {
        return new Promise((resolve) => {
            global.log('Exporting ' + (this.transparentBackground === false ? 'non-transparent' : 'transparent') + ' image in <i>' + this.fileFormat + '</i> format at <i>' + global.storeReference.state.export.width + 'px x ' + global.storeReference.state.export.height + 'px</i>');

            // Stop three.js animation loop
            this.threeManager.stopAnimationLoop();

            // Determine export target size
            this.threeManager.renderer.getSize(this.originalRendererSize);

            // Determine targetSize (resulting size of export) as well as the sliceSize (size of each individual slice)
            this.targetSize = { x: global.storeReference.state.export.width, y: global.storeReference.state.export.height };
            this.sliceSize = { x: Math.ceil(this.targetSize.x / this.amountOfSlices), y: this.targetSize.y }

            // Generate slices
            for (let i = 0; i < this.amountOfSlices; i++) {
                // Determine x-offset of current slice
                let sliceXOffset = i * this.sliceSize.x;

                // Set camera dimensions and renderer to slice
                this.resizeRenderer(this.sliceSize.x, this.sliceSize.y);
                this.threeManager.camera.setViewOffset(this.targetSize.x, this.targetSize.y, sliceXOffset, 0, this.sliceSize.x, this.sliceSize.y);
                this.threeManager.renderer.clear();
                this.threeManager.render();

                // Copy rendered frame to canvas element
                let canvas = document.createElement('canvas');
                canvas.width = this.sliceSize.x;
                canvas.height = this.sliceSize.y;
                canvas.getContext('2d').drawImage(this.threeManager.renderer.domElement, 0, 0);

                // Push rendered canvas element to array
                this.renderedSlices.push(canvas);

                // Resolve on final slice
                if (i == this.amountOfSlices - 1) { resolve('OK'); }
            }

        });
    }




    stichSlices() {
        return new Promise((resolve) => {
            // Create canvas
            let canvas = document.createElement('canvas');
            canvas.width = this.targetSize.x;
            canvas.height = this.targetSize.y;

            // Draw all slices to the canvas
            for (let i = 0; i < this.amountOfSlices; i++) {
                canvas.getContext('2d').drawImage(this.renderedSlices[i], this.sliceSize.x * i, 0);
            }

            // Resolve
            resolve(canvas);
        });
    }





    resizeRenderer(w, h) {
        this.threeManager.camera.aspect = w / h;
        this.threeManager.camera.updateProjectionMatrix();
        this.threeManager.renderer.setSize(w, h, true);
    }

}
