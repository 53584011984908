<template>
  <Window title="Performance" open="false" width="150px" right="30px" top="30px" :icon="$store.getters.getPublicPath + 'UI/icon_stats.svg'" ref="perfWindow">
    <div id="UI_performance">
      <!-- Stats.js -->
      <div id="UI_stats_performance"></div>
      <div id="UI_stats_perfInfo">&#9432; Click to cycle metrics</div>

      <!-- Renderer stats -->
      <div id="UI_stats_threeJS" style="font-size: 10px">
        <table>
          <tr>
            <td>Geometries:</td>
            <td v-if="windowVisible" v-html="$store.state.UI.performance.stats.geometries"></td>
          </tr>
          <tr>
            <td>Rendercalls:</td>
            <td v-if="windowVisible" v-html="$store.state.UI.performance.stats.renderCalls"></td>
          </tr>
          <tr>
            <td>Frame:</td>
            <td v-if="windowVisible" v-html="$store.state.UI.performance.stats.frame"></td>
          </tr>
          <tr>
            <td>Triangles:</td>
            <td v-if="windowVisible" v-html="$store.state.UI.performance.stats.triangles"></td>
          </tr>
        </table>
      </div>
    </div>
  </Window>
</template>

<script>
// Vue
import { useStore } from "vuex";

// UI components
import Window from "@/components/UI/components/window.vue";
import { ref } from "@vue/reactivity";
import { getCurrentInstance, onMounted, watch } from "@vue/runtime-dom";

export default {
  components: {
    Window,
  },
  name: "PerformanceWindow",
  setup() {
    const store = useStore();

    // Monitor the window. If it is not visible, don't update the stats in the DOM.
    let perfWindow = ref(null);
    let windowVisible = ref(false);
    onMounted(() => {
      // Mark window as mounted in the VueX store (so other components can subscribe and use it once its available)
      let componentName = getCurrentInstance().type.name;
      store.dispatch("UI_mountWindow", { window: componentName });

      //
      if (store.state.enableStats) global.threeManager.init_stats();

      //
      windowVisible.value = perfWindow.value.windowVisible;
      watch(
        () => perfWindow.value.windowVisible,
        () => {
          windowVisible.value = perfWindow.value.windowVisible;
        }
      );
    });

    return { store, windowVisible, perfWindow };
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/theming.scss";
#UI_performance {
  padding: 20px;
}
#UI_stats_perfInfo {
  display: block;
  font-size: 10px;
}
#UI_stats_threeJS {
  margin-top: 20px;
}
</style>