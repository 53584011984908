<template>
  <Window title="Console" width="450px" height="300px" right="200px" top="30px" :icon="$store.getters.getPublicPath + 'UI/icon_console.svg'">
    <div id="UI_consoleContent">
      <div id="ui_console_sticky"><span v-html="$store.state.UI.console.sticky" /></div>
      <div id="ui_console_content"><span v-html="$store.state.UI.console.content" /></div>
    </div>
  </Window>
</template>

<script>
// Vue
import { useStore } from "vuex";
import { getCurrentInstance, onMounted } from '@vue/runtime-dom';

// UI components
import Window from "@/components/UI/components/window.vue";
export default {
  components: {
    Window,
  },
  name: "ConsoleWindow",
  setup() {
    const store = useStore();

    onMounted(() => {
      // Mark window as mounted in the VueX store (so other components can subscribe and use it once its available)
      let componentName = getCurrentInstance().type.name;
      store.dispatch("UI_mountWindow", { window: componentName });
    });

    return { store };
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/theming.scss";
#UI_consoleContent {
  padding: 20px;
}
#ui_console_sticky {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  max-height: 30px;
  padding: 10px 20px 10px 20px;
  @include theme(border-top, UI_window_fontColor, 1px solid);
}
#ui_console_content {
  margin-bottom: 45px;
}
</style>