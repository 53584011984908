<template>
    <div class="questionSelector">
        <ul>
            <li @click="selectQuestion" v-for="(question, index) in $store.state.data.questions" :key="index" :data-ID="question.questionIndex">{{ question.question }}</li>
        </ul>
    </div>
</template>

<script>
import { useStore } from "vuex";

export default {
    name: "questionSelector",
    setup() {
        const store = useStore();

        function selectQuestion(event) {
            // TargetID is the ID supplied by the data-attribute
            let targetID = parseFloat(event.target.getAttribute('data-ID'));

            // Get the index in the Object from that ID
            let selectedQuestionIndex = Object.values(store.state.data.questions).findIndex((element) => element.questionIndex == targetID)

            // Update question index
            store.dispatch("updateState", { parent: "data", key: "activeQuestionIndex", value: selectedQuestionIndex });

            // Determine if question is segmented
            let currentQuestionIsSegmented = typeof Object.values(store.state.data.questions)[store.state.data.activeQuestionIndex].segments == 'undefined' ? false : true;

            // Set new activeQuestion and segment index
            if (!currentQuestionIsSegmented) {
                store.dispatch("updateState", { parent: "data", key: "activeQuestion", value: Object.values(store.state.data.questions)[store.state.data.activeQuestionIndex] });
            } else {
                store.dispatch("updateState", { parent: "data", key: "activeQuestionSegmentIndex", value: 0 });
                store.dispatch("updateState", { parent: "data", key: "activeQuestion", value: Object.values(store.state.data.questions)[store.state.data.activeQuestionIndex].segments[store.state.data.activeQuestionSegmentIndex] });
            }

            store.dispatch("updateState", { parent: "UI", key: "questionSelectorActive", value: false });

        }

        return { store, selectQuestion };
    },
};
</script>

<style lang="scss">
@import "@/assets/styles/theming.scss";

.questionSelector {
    pointer-events: all;
    background-color: #5a5a5a;
    height: 170px;
    width: 135px;
    z-index: 1000;
    position: absolute;
    margin-top: -240px;
    border-radius: 10px;

    box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
        6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
        12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
        22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
        41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
        100px 100px 80px rgba(0, 0, 0, 0.07);

    ul {
        overflow-y: scroll;
        height: 150px;
        padding: 0;
        margin: 10px 0 0 10px;
    }

    li {
        display: block;
        width: auto;
        font-size: 16px;
        padding: 10px;
        list-style: none;
        cursor: pointer;

        &:hover {
            @include theme(background-color, highlightColor)
        }
    }
}
</style>