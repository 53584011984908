<template>

  <!-- DESIGN SYSTEM -->
  <div id="container">
    <div id="WEBGL"></div>
    <div id="labels"></div>
  </div>

  <!-- VIGNETTE -->
  <div id="vignette"></div>


  <!-- NAVIGATION UI -->
  <div id="navigationUI">
    <div id="questionContainer">

      <!-- <div id="question">{{ question }} <span :class="$store.state.data.filterObjects.length > 0 ? 'activeFilter' : ''">{{ totalAnswers }} answers total</span></div> -->
      <div id="question">{{ question }}</div>

      <div id="questionNav">
        <div class="navigationButton" :class="$store.state.UI.questionSelectorActive == true ? 'questionSelectorIconActive' : ''" id="questionSelector" @click="toggleQuestionSelector" style="margin-right: 20px;"><img :src="$store.getters.getPublicPath + 'UI/arrow-questionIndex.svg'" alt="Select question from database" /></div>
        <div class="navigationButton" id="questionNavLeft" @click="prevQuestion"><img :src="$store.getters.getPublicPath + 'UI/arrow-left.svg'" alt="Previous question" /></div>
        <div class="navigationButton" id="questionNavRight" @click="nextQuestion"><img :src="$store.getters.getPublicPath + 'UI/arrow-right.svg'" alt="Next question" /></div>

        <div id="infoArea">
          <div class="navigationButton" id="resetAllFilters" @click="resetFilters" v-show="$store.state.data.filterObjects.length > 0"><span>Reset all filters</span></div>
          <div class="navigationButton" id="activeFilter" @click="resetFilters" v-show="$store.state.data.filterObjects.length > 0"><span>Based on {{ totalAnswers }} answers</span></div>
        </div>
      </div>

      <QuestionSelector v-show="$store.state.UI.questionSelectorActive" />

      <div id="answer">
        <div id="primaryAnswer">{{ answer }}</div>
        <div v-show="subAnswer != null" id="subAnswer">
          {{ subAnswer }}
        </div>
      </div>
    </div>
  </div>

  <div id="dividerVertical"></div>

  <!-- INTERNAL UI -->
  <UI />
</template>

<script>
// Vue
import { useStore } from "vuex";
import { ref } from "@vue/reactivity";
import { onMounted, onUnmounted, watch } from "@vue/runtime-dom";

// THREE.js
import importedSketchObject from "@/THREE/DesignSystem.js";
import THREE_Manager from "@/managers/THREE_Manager.js";
let threeManager = ref(null); // reference to the THREE.js manager that handles all WebGL operations
export { threeManager }; // export THREE.js manager-reference to expose it to other components

// Components
import UI from "@/components/UI/UI.vue";
import QuestionSelector from "@/components/UI/components/questionSelector.vue";

export default {
  name: "DesignSystem",
  components: {
    UI,
    QuestionSelector,
  },
  setup() {
    const store = useStore();

    // QUESTION DATA ON SCREEN ---------------------------------------------------------------------------------------------
    const question = ref("Awaiting data from server...");
    const answer = ref("");
    const subAnswer = ref(null);
    const totalAnswers = ref(0);





    // WATCH MAIN CHANGES, THEN UPDATE THE CORRESPONDING STRINGS IN THE UI ---------------------------------------------------------------------------------------------
    // Watch for changes in the filtered data
    watch(() => store.state.data.filterAmount, () => {
      updateAnswerCount();
    });

    // Watch for changes to the overall question data or activeQuestion
    watch(
      () => [store.state.data.questions, store.state.data.activeQuestion],
      () => {
        if (store.state.data.activeQuestion == null) return;

        updateAnswerCount();

        question.value = store.state.data.activeQuestion.question;
        answer.value = store.state.data.activeQuestion.answer;
        subAnswer.value = store.state.data.activeQuestion.subAnswer;
      }
    );

    // Update the answer-count reference either based on total amount of answers or the current amount of filtered objects
    function updateAnswerCount() {
      if (store.state.data.filterObjects.length < 1) {
        totalAnswers.value = store.state.data.activeQuestion.data.length;
      } else {
        totalAnswers.value = store.state.data.filterAmount;
      }
    }






    // HANDLE MOUNTING AND UNMOUNTING OF COMPONENT ---------------------------------------------------------------------------------------------
    onMounted(() => {
      threeManager = new THREE_Manager({
        parentContainer: "WEBGL",
        sketchObject: importedSketchObject,
      });

      document.getElementById('primaryAnswer').style.marginLeft = '0px';
      document.getElementById('subAnswer').style.marginLeft = '0px';
    });

    onUnmounted(() => {
      threeManager.destroy();
      threeManager = ref(null);
      UI.methods.destroy();
    });






    // RESET ALL FILTERS ---------------------------------------------------------------------------------------------
    function resetFilters() {
      threeManager.sketch.resetAllFilters();
    }





    // NAVIGATION OF QUESTIONS ---------------------------------------------------------------------------------------------
    function prevQuestion() {
      store.dispatch("updateState", { parent: "UI", key: "questionSelectorActive", value: false });
      navigateQuestions('prev', -1);
    }

    function nextQuestion() {
      store.dispatch("updateState", { parent: "UI", key: "questionSelectorActive", value: false });
      navigateQuestions('next', 1);
    }

    function animateAnswer(animatePrimaryAnswer) {
      if (animatePrimaryAnswer != false) {
        // Primary answer
        let primAnswer = document.getElementById('primaryAnswer');
        primAnswer.style.transition = '0s';
        primAnswer.style.marginLeft = '-600px';
        setTimeout(() => {
          primAnswer.style.transition = '.5s';
          primAnswer.style.marginLeft = '0px';
        }, 100);
      }

      // Secondary answer
      let subAnswer = document.getElementById('subAnswer');
      subAnswer.style.transition = '0s';
      subAnswer.style.marginLeft = '-600px';
      setTimeout(() => {
        subAnswer.style.transition = '.9s';
        subAnswer.style.marginLeft = '0px';
      }, 100);

    }

    function navigateQuestions(direction, increment) {
      // Is the current question a segmented question?
      let currentQuestionIsSegmented = typeof Object.values(store.state.data.questions)[store.state.data.activeQuestionIndex].segments == 'undefined' ? false : true;

      // If the current question is not segmented
      if (!currentQuestionIsSegmented) {
        animateAnswer();
        goToNextQuestion();
      } else {
        // If the current question does have segments
        goToNextSegment();
      }

      // >>> Navigation: Next segment
      function goToNextSegment() {
        // Determine amount of total segements in this question
        let segmentLength = Object.values(store.state.data.questions)[store.state.data.activeQuestionIndex].segments.length;

        // Navigation in direction of variable
        let segmentAvailable;
        if (direction == 'next') { segmentAvailable = store.state.data.activeQuestionSegmentIndex + 1 < segmentLength; }
        if (direction == 'prev') { segmentAvailable = store.state.data.activeQuestionSegmentIndex - 1 >= 0; }

        // If there is a next segment available
        if (segmentAvailable) {
          animateAnswer(false);
          store.dispatch("updateState", { parent: "data", key: "activeQuestionSegmentIndex", value: store.state.data.activeQuestionSegmentIndex + increment });
          store.dispatch("updateState", { parent: "data", key: "activeQuestion", value: Object.values(store.state.data.questions)[store.state.data.activeQuestionIndex].segments[store.state.data.activeQuestionSegmentIndex] });
        } else {
          animateAnswer();
          // If there are no more segments, go to the next question
          goToNextQuestion();
        }
      }

      // >>> Navigation: Next question
      function goToNextQuestion() {
        // Determine the index of the next question
        let nextQuestionIndex;
        if (direction == 'next') { nextQuestionIndex = store.state.data.activeQuestionIndex + 1 < Object.keys(store.state.data.questions).length ? store.state.data.activeQuestionIndex + 1 : 0; }
        if (direction == 'prev') { nextQuestionIndex = store.state.data.activeQuestionIndex - 1 >= 0 ? store.state.data.activeQuestionIndex - 1 : Object.keys(store.state.data.questions).length - 1 }

        // Determine if the next question is segmented
        let nextQuestionIsSegmented = typeof Object.values(store.state.data.questions)[nextQuestionIndex].segments == 'undefined' ? false : true;

        // Set index of next question and segmentation index back to 0
        store.dispatch("updateState", { parent: "data", key: "activeQuestionIndex", value: nextQuestionIndex });

        // If the next question is segmented, select the fitting segment
        if (nextQuestionIsSegmented) {
          // Select the corresponding starting point for the segment 
          if (direction == 'next') { store.dispatch("updateState", { parent: "data", key: "activeQuestionSegmentIndex", value: 0 }); }
          if (direction == 'prev') { store.dispatch("updateState", { parent: "data", key: "activeQuestionSegmentIndex", value: Object.values(store.state.data.questions)[store.state.data.activeQuestionIndex].segments.length - 1 }); }

          // Set the segment as the new active question
          store.dispatch("updateState", { parent: "data", key: "activeQuestion", value: Object.values(store.state.data.questions)[store.state.data.activeQuestionIndex].segments[store.state.data.activeQuestionSegmentIndex] });
        } else {
          // If the question is not segmented, set the next question as active
          store.dispatch("updateState", { parent: "data", key: "activeQuestionSegmentIndex", value: 0 });
          store.dispatch("updateState", { parent: "data", key: "activeQuestion", value: Object.values(store.state.data.questions)[store.state.data.activeQuestionIndex] });
        }
      }
    }



    //
    function toggleQuestionSelector() {
      store.dispatch("updateState", { parent: "UI", key: "questionSelectorActive", value: !store.state.UI.questionSelectorActive });
    }



    // EXPOSE FUNCTIONS AND VARIABLES ---------------------------------------------------------------------------------------------
    return { question, answer, subAnswer, prevQuestion, nextQuestion, resetFilters, totalAnswers, toggleQuestionSelector };

  },
};
</script>





<style lang="scss">
@import "@/assets/styles/theming.scss";
</style>
