// THREE
import * as THREE from 'three';

// Vue
import { watch } from 'vue';
import { useStore } from "vuex";

// Data
import DATA_Manager from '@/managers/DATA_Manager.js';
import Interface from './Interface.js';

// Custom
const glsl = require('glslify');
import { map, random } from '@/utils/utils.js';
import { gsap, Quad } from 'gsap';
import CustomMaterial from '@/THREE/shader/CustomMaterial.js'


export default class Sketch {

    constructor(args) {
        // VueX
        this.store = useStore();

        // Data manager
        this.dataManager = new DATA_Manager({});

        // Three.js
        this.three = args.threeManager;
        this.watchForData();
        this.addThemeReactivity();
    }


    // INIT ---------------------------------------------------------------------------------------------

    init() {
        // Remove prior scene content
        while (this.three.scene.children.length > 0) {
            this.three.scene.remove(this.three.scene.children[0]);
        }

        // Reset filtered objects
        this.store.dispatch("updateState", { parent: "data", key: "filterObjects", value: [] });
        this.store.dispatch("updateState", { parent: "data", key: "filterAmount", value: 0 });

        // Init interface
        this.initInterface();

        // Create the graphic
        this.createGraphic();

        // Add backdrop
        this.addBackdrop();

        // Add lights
        this.addLights();
    }


    update() {
        if (this.interface) this.interface.update();
    }


    destroy() {
    }


    // Initialize system when data is all ready
    watchForData() {
        // Watch for changes to the active question
        watch(() => [this.store.state.data.activeQuestion], () => {
            this.init();
        });

        // Watch for changes to the visual style
        watch(() => [this.store.state.colors.flatDesign, this.store.state.colors.flatLines], () => {
            if (this.store.state.colors.flatDesign) this.store.state.colors.flatLines = true;
            this.init();
        });

        // Watch for changes in filtered objects
        watch(() => this.store.state.data.filterObjects, () => {
            this.visualizeLineConnections();
            this.updateMarkers();
        },
            { deep: true });
    }


    addThemeReactivity() {
        // Watch the state for a theme-change
        watch(() => this.store.getters.themeHasChanged, () => { this.themeChange(); })

        // Fire once on startup to react to current theme state
        this.themeChange();
    }



    // -----------------------------------------------------------------------------------------------------
    // METHODS ---------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------



    //
    addBackdrop() {
        if (this.store.state.colors.flatDesign) return;

        if (this.backdrop != undefined) {
            this.three.scene.remove(this.backdrop);
        }
        if (this.store.state.UI.theme == 'light') return;

        if (this.store.state.UI.theme == 'dark') {
            document.getElementById('questionContainer').style.backgroundColor = 'rgba(0,0,0,0)';
        }


        let loader = new THREE.TextureLoader();
        let gradientToLoad = this.store.state.UI.theme == 'dark' ? 'gradient-dark.jpg' : 'gradient-light.jpg';
        this.backdrop = new THREE.Mesh(
            new THREE.IcosahedronGeometry(120, 10),
            new THREE.MeshBasicMaterial({
                map: loader.load('./textures/' + gradientToLoad),
                side: THREE.BackSide,
                depthWrite: false
            })
        );
        this.three.scene.add(this.backdrop);
    }


    //
    addLights() {
        if (this.store.state.colors.flatDesign) return;

        this.topLight = new THREE.DirectionalLight(0xffffff, 1);
        this.topLight.position.set(3, 10, 0);
        this.topLight.castShadow = true;
        this.three.scene.add(this.topLight);

        this.bottomLight = new THREE.DirectionalLight(0xffffff, .5);
        this.bottomLight.position.set(10, -15, 10);
        this.bottomLight.castShadow = true;
        this.three.scene.add(this.bottomLight);
    }




    // CREATE THE ACTUAL GRAPHIC ---------------------------------------------------------------------------------------------
    createGraphic() {
        // #1
        // Create the data markers
        this.createDataMarkers();

        // #2
        // Create the group for all curves
        this.curveGroup = new THREE.Group();
        this.curveGroup.name = "All connecting lines";
        this.three.scene.add(this.curveGroup);

        // Create the line connections
        this.createLineConnections();

        // setTimeout(() => {
        //     this.store.dispatch("data_addFilterObject", { ID: 1 });
        //     // this.store.dispatch("data_addFilterObject", { ID: 19 });
        //     // this.store.dispatch("data_addFilterObject", { ID: 56 });
        // }, 100);

    }





    // CREATE THE INDIVIDUAL LINE CONNECTIONS ---------------------------------------------------------------------------------------------
    createLineConnections() {
        for (let index = 0; index < Object.values(this.store.state.data.objectPositions).length; index++) {
            // Determine which section to visualize
            let dataToVisualize = Object.keys(this.store.state.data.objectPositions)[index];

            if (dataToVisualize != "Answer") {
                for (let i = 0; i < this.store.state.data.activeQuestion.data.length; i++) {
                    // Grab the stored data (which is an index-reference) from the active question
                    let answerToConnectTo = this.store.state.data.activeQuestion.data[i]["Answer"];
                    let dataToConnectTo = this.store.state.data.activeQuestion.data[i][dataToVisualize];

                    if (answerToConnectTo == -1) { }
                    else {
                        // Check if there is corrupted data and if so, state a short info
                        if (this.store.state.data.objectPositions[dataToVisualize].computedPositions[this.store.state.data.objectPositions[dataToVisualize].computedPositions.findIndex(x => x.includes(dataToConnectTo))] == undefined) {
                            console.error('Data point corrupted, ignoring... Section: ' + dataToVisualize + '. Index: ' + i);
                        } else {
                            // Based on the data above, grab the location of both locations from the store via the index
                            let answerLocation = this.store.state.data.objectPositions["Answer"].computedPositions[this.store.state.data.objectPositions["Answer"].computedPositions.findIndex(x => x.includes(answerToConnectTo))][0];
                            let dataLocation = this.store.state.data.objectPositions[dataToVisualize].computedPositions[this.store.state.data.objectPositions[dataToVisualize].computedPositions.findIndex(x => x.includes(dataToConnectTo))][0];

                            if (answerLocation == undefined || dataLocation == undefined) { }
                            else {
                                // Create object which contains to data-connection references
                                let mapToData = {
                                    segment: dataToVisualize,
                                    answerToConnectTo: answerToConnectTo,
                                    dataToConnectTo: dataToConnectTo
                                };
                                // Create the curve
                                this.createCurve(answerLocation, dataLocation, mapToData, i);
                            }
                        }
                    }
                }
            }
        }
    }

    // FUNCTION TO CREATE AN INDIVIDUAL CURVE ---------------------------------------------------------------------------------------------
    createCurve(sourceLocation, targetLocation, dataPoint, elementIndex) {
        // #1 Create points
        // Create a random point between source and target vector
        let sourceClone = new THREE.Vector3(sourceLocation.x, sourceLocation.y, sourceLocation.z)
        let targetClone = new THREE.Vector3(targetLocation.x, targetLocation.y, targetLocation.z)
        let rndPoint = sourceClone.lerp(targetClone, .5);
        rndPoint.y += Math.random() * 5 - 2.5
        rndPoint.z = Math.random() * 5 - 2.5;

        // Make points array
        let points = [sourceLocation, rndPoint, targetLocation];

        // #2 Create path
        let path = new THREE.CatmullRomCurve3(points);
        let pathThickness = 0.011; // 0.009

        // #3 Initialize Shader material
        let material;
        if (this.store.state.colors.flatLines) {
            const lineUniforms = {
                time: { type: 'f', value: 0 },
                uColor: { value: new THREE.Color(this.store.state.colors.primary) },
                uOpacity: { value: 1 }
            }

            material = new THREE.ShaderMaterial({
                uniforms: lineUniforms,
                vertexShader: glsl(require('./shader/line-vertex.glsl').default),
                fragmentShader: glsl(require('./shader/line-fragment.glsl').default),
                transparent: true,
            });
        } else {
            material = new CustomMaterial({});
            material.color = new THREE.Color(this.store.state.colors.primary);
            pathThickness = 0.05;
        }

        // #4 Create curve
        let geometry = new THREE.TubeBufferGeometry(
            path,
            50, // 20
            pathThickness,
            3,
            false
        );

        // Create curve mesh and attach data
        let curve = new THREE.Mesh(geometry, material);
        curve.userData.data = dataPoint;
        this.curveGroup.add(curve);

        // Add curve information to activeQuestion
        if (this.store.state.data.activeQuestion.data[elementIndex]['curves'] == undefined) {
            // If the curves section does not exist, create it
            this.store.state.data.activeQuestion.data[elementIndex]['curves'] = {}
        }
        this.store.state.data.activeQuestion.data[elementIndex]['curves'][dataPoint.segment] = curve;

        // Animate
        gsap.to(material.uniforms.time, {
            duration: random(.5, 2),
            value: 1,
        });
    }




    // CREATE THE 3D DATA MARKERS AND ADD TO SCENE GRAPH ---------------------------------------------------------------------------------------------
    createDataMarkers() {
        this.dataMarkers = [];

        let markerID = 0;
        for (let index = 0; index < Object.values(this.store.state.data.objectPositions).length; index++) {
            // Determine which section to visualize
            let dataToVisualize = Object.keys(this.store.state.data.objectPositions)[index];

            // Get the positions
            let positions = this.dataManager.getPositionData(dataToVisualize);

            // Create a group to house the objects and add to scene graph
            let sectionGroup = new THREE.Group();
            sectionGroup.name = dataToVisualize;
            this.three.scene.add(sectionGroup);

            // Create 3D instances
            for (let i = 0; i < positions.length; i++) {
                // Grab description & index
                let description; // Description of current sub-section
                let currentIndex; // Index of current sub-section
                if (dataToVisualize == 'Answer') {
                    // Data type: Answer
                    description = Object.values(this.store.state.data.index[dataToVisualize]['Question ' + this.store.state.data.activeQuestion.questionIndex])[i];
                    currentIndex = Object.keys(this.store.state.data.index[dataToVisualize]['Question ' + this.store.state.data.activeQuestion.questionIndex])[i];
                } else {
                    // All other types of data
                    description = Object.values(this.store.state.data.index[dataToVisualize])[i];
                    currentIndex = Object.keys(this.store.state.data.index[dataToVisualize])[i];
                }

                // Determine size of object, based on distribution of data
                let instancesFound = 0; // how many instances of this answer were found
                let validAnswers = this.store.state.data.activeQuestion.data.length // which answers were valid (an actual answer, not a blank field)
                for (let j = 0; j < this.store.state.data.activeQuestion.data.length; j++) {
                    let dataEntity = this.store.state.data.activeQuestion.data[j][dataToVisualize];
                    if (dataEntity == currentIndex) instancesFound++;
                    if (dataEntity == -1) validAnswers--; // -1 = this field was not filled in and has to be ignored
                }
                let average = instancesFound / validAnswers;
                let objectSize = map(average, 0, 1, 0, 2.5);

                // Store in the positions array at the second position which index this refers to 
                positions[i][1] = parseFloat(currentIndex);

                // Make the 3D instance
                this.makeInstance(
                    new THREE.SphereBufferGeometry(1, 32, 32),
                    objectSize,
                    new THREE.Color(this.store.state.colors.primary),
                    positions[i][0],
                    description,
                    { segment: dataToVisualize, index: parseFloat(currentIndex) },
                    sectionGroup,
                    markerID,
                );

                // Increase marker ID
                markerID++;
            }

        }
        // Make labels clickable
        this.interface.addLabelInteractivity();
    }

    // FUNCTION TO CREATE A 3D OBJECT ---------------------------------------------------------------------------------------------
    makeInstance(geometry, objectSize, color, position, name, mapToData, parentGroup, ID) {
        // The DOM label
        let elem = document.createElement('div');
        elem.textContent = name;
        elem.className = 'label';
        let elemSpan = document.createElement('span');
        elemSpan.textContent = '0';
        elem.appendChild(elemSpan);
        this.interface.labelContainer.appendChild(elem);

        // Material
        let material;
        if (this.store.state.colors.flatDesign) {
            material = new THREE.MeshBasicMaterial({
                color: color,
            });
        } else {
            material = new THREE.MeshPhysicalMaterial({
                color: color,
                reflectivity: 0.5,
                clearcoat: 1,
                clearcoatRoughness: 0.5,
            });
        }

        // The 3D object
        let mesh = new THREE.Mesh(geometry, material);
        mesh.position.x = position.x;
        mesh.position.y = position.y;
        mesh.position.z = position.z;
        mesh.name = name;
        mesh.userData.ID = ID;
        mesh.userData.elem = elem;
        mesh.userData.mapToData = mapToData;
        mesh.userData.originalSize = objectSize;
        mesh.scale.set(objectSize, objectSize, objectSize);
        parentGroup.add(mesh);

        // Shadows
        if (!this.store.state.colors.flatDesign) {
            mesh.castShadow = true;
            mesh.receiveShadow = true;
        }

        // Set to 0 so it gets animated in
        mesh.scale.x = 0;
        mesh.scale.y = 0;
        mesh.scale.z = 0;

        // Push to arrays for DOM-label updates and raycasting
        this.interface.labeledObjects.push({ mesh, elem })
        this.interface.raycastObjects.push(mesh);

        // Push to internal data marker array
        this.dataMarkers.push(mesh);

        return { mesh, elem };
    }
















    // INITIALIZE THE 3D INTERFACE ---------------------------------------------------------------------------------------------
    initInterface() {
        this.interface = new Interface({ threeManager: this.three, designSystem: this });
    }

    // RESET ALL FILTERS ---------------------------------------------------------------------------------------------
    resetAllFilters() {
        this.store.dispatch("updateState", { parent: "data", key: "filterObjects", value: [] });
        this.store.dispatch("updateState", { parent: "data", key: "filterAmount", value: 0 });
        this.colorizeLines(this.store.state.colors.primary);
        let labels = document.getElementsByClassName('label');
        for (let index in labels) {
            if (typeof labels[index].classList != 'undefined')
                labels[index].classList.remove('hovered');
        }
    }






    // CHANGE THEME COLORS ---------------------------------------------------------------------------------------------
    themeChange() {
        // Change state colors
        if (this.store.state.UI.theme == "dark") {
            this.store.dispatch("updateState", { parent: "colors", key: "background", value: "#1f2023" });
            this.store.dispatch("updateState", { parent: "colors", key: "primary", value: "#eeeeee" });
            this.store.dispatch("updateState", { parent: "colors", key: "highlightColor", value: "#96d0d3" });
            this.store.dispatch("updateState", { parent: "colors", key: "inactiveColor", value: "#2b2c30" });
            document.getElementById('labels').classList.add('darkMode');
        }
        if (this.store.state.UI.theme == "light") {
            this.store.dispatch("updateState", { parent: "colors", key: "background", value: "#ffffff" });
            this.store.dispatch("updateState", { parent: "colors", key: "primary", value: "#1f2023" });
            this.store.dispatch("updateState", { parent: "colors", key: "highlightColor", value: "#519bc6" });
            this.store.dispatch("updateState", { parent: "colors", key: "inactiveColor", value: "#949494" });
            document.getElementById('labels').classList.remove('darkMode');
        }

        // Set the background
        this.three.renderer.setClearColor(new THREE.Color(this.store.state.colors.background));

        //
        this.addBackdrop();

        // Colorize lines and object
        this.colorizeLines(this.store.state.colors.primary);

        //
        this.visualizeLineConnections();
    }
    colorizeLines(targetColor) {
        // Colorize lines and objects
        for (let i = 0; i < this.three.scene.children.length; i++) {
            // Meshes
            if (this.three.scene.children[i].type == 'Mesh') {
                this.three.scene.children[i].material.color = new THREE.Color(targetColor);
            }
            // Groups
            if (this.three.scene.children[i].type == 'Group') {
                for (let j = 0; j < this.three.scene.children[i].children.length; j++) {
                    if (typeof this.three.scene.children[i].children[j].material.uniforms == 'undefined') {
                        // Meshes
                        this.three.scene.children[i].children[j].material.color = new THREE.Color(targetColor);
                    } else {
                        // Lines with shaderMaterial
                        if (this.store.state.colors.flatLines) {
                            this.three.scene.children[i].children[j].material.uniforms.uColor.value = new THREE.Color(targetColor);
                        } else {
                            this.three.scene.children[i].children[j].material.color = new THREE.Color(targetColor);
                            this.three.scene.children[i].children[j].material.emissive = new THREE.Color('#000000');
                        }
                    }
                }
            }
        }

        // Colorize those elements which have been marked as active filters
        if (this.interface)
            this.interface.colorizeFilterMeshes();
    }






    // VISUALIZE LINE CONNECTIONS, BASED ON THE AMOUNT OF OBJECTS SELECTED
    visualizeLineConnections() {
        // No object selected: Reset colors of all lines
        if (this.store.state.data.filterObjects.length < 1) {
            if (this.curveGroup) {
                gsap.globalTimeline.clear();

                for (let i = 0; i < this.curveGroup.children.length; i++) {
                    let child = this.curveGroup.children[i];
                    if (this.store.state.colors.flatLines) {
                        child.material.uniforms.uColor.value = new THREE.Color(this.store.state.colors.primary);
                    } else {
                        child.material.color = new THREE.Color(this.store.state.colors.primary);
                        child.material.emissive = new THREE.Color('#000000');
                    }
                    child.material.uniforms.uOpacity.value = 1;
                    gsap.to(child.material.uniforms.time, {
                        duration: random(0.5, 1),
                        value: 1,
                    });
                    child.visible = true;
                    child.userData.activeFilter = false;
                }

                this.store.dispatch("updateState", { parent: "data", key: "filterAmount", value: 0 });
            }
        }

        // One object selected: Visualize corresponding branches
        if (this.store.state.data.filterObjects.length == 1) {
            this.visualizeSingleDatapoint();
        }

        // Multiple objects selected: Visualize multiple filtered datapoints
        if (this.store.state.data.filterObjects.length > 1) {
            this.visualizeMultipleDatapoints();
        }
    }





    // VISUALIZE A SINGLE DATA POINT
    visualizeSingleDatapoint() {
        // #1 Make all objects invisible
        if (this.curveGroup) {
            gsap.globalTimeline.clear();
            for (let i = 0; i < this.curveGroup.children.length; i++) {
                let child = this.curveGroup.children[i];
                gsap.to(child.material.uniforms.time, {
                    duration: random(0.5, 1),
                    value: 0,
                });
            }
        }

        // #2 Find the active object and grab data reference
        let mapToData = null;
        this.three.scene.traverse((element) => {
            if (element instanceof THREE.Mesh && this.store.state.data.filterObjects.includes(element.userData.ID)) {
                mapToData = element.userData.mapToData;
            }
        });
        if (mapToData == null) return;


        // #3 Create a new dataset, based on the "activeQuestion" dataset, which only contains data with our "activeFilter" content
        let dataset = this.store.state.data.activeQuestion.data;
        this.filterResult = [];
        for (let i = 0; i < dataset.length; i++) {
            let curDataset = dataset[i];
            if (curDataset[mapToData.segment] == mapToData.index) {
                this.filterResult.push(curDataset);
            }
        }
        this.store.dispatch("updateState", { parent: "data", key: "filterAmount", value: this.filterResult.length });


        // #4 Activate the corresponding lines by looping through the "filterResult"
        for (let index in this.filterResult) {
            let dataPoint = this.filterResult[index]['curves'];

            // Loop over each nested curve
            for (let i in dataPoint) {
                let curveMesh = dataPoint[i];

                if (this.store.state.colors.flatLines) {
                    curveMesh.material.uniforms.uColor.value = new THREE.Color(this.store.state.colors.highlightColor);
                } else {
                    curveMesh.material.color = new THREE.Color(this.store.state.colors.highlightColor);
                    curveMesh.material.emissive = new THREE.Color(this.store.state.colors.highlightColor);
                }
                curveMesh.material.uniforms.uOpacity.value = 1;
                curveMesh.visible = true;

                // Animate
                curveMesh.material.uniforms.time.value = 0;
                gsap.to(curveMesh.material.uniforms.time, {
                    duration: random(.5, 2),
                    value: 1,
                });
                curveMesh.userData.activeFilter = true;

            }
        }
    }





    // VISUALIZE MULTIPLE DATA POINTS (MULTIPLE FILTERS)
    visualizeMultipleDatapoints() {
        // #1 Make all objects invisible
        if (this.curveGroup) {
            gsap.globalTimeline.clear();
            for (let i = 0; i < this.curveGroup.children.length; i++) {
                let child = this.curveGroup.children[i];
                gsap.to(child.material.uniforms.time, {
                    duration: random(0.5, 1),
                    value: 0,
                });
            }
        }

        // #2 Build an array of the objects which are marked as active filters
        this.filterObjectData = [];
        this.filterAnswerData = [];
        this.activeFilter = {};
        this.three.scene.traverse((element) => {
            // Check if the current element is of type Mesh and matches any of the IDs in the "filterObjects" array
            if (element instanceof THREE.Mesh && this.store.state.data.filterObjects.includes(element.userData.ID)) {
                // Push the data to either the "filterAnswerData" or "filterObjectData" array
                if (element.userData.mapToData.segment == 'Answer') {
                    this.filterAnswerData.push(element.userData.mapToData.index);
                } else {
                    this.filterObjectData.push(element.userData.mapToData);
                }

                // Build the key in the "activeFilter" object if it doesn't yet exist - this is used for segmenting the data when visualizing the marker sizes
                if (typeof this.activeFilter[element.userData.mapToData.segment] == 'undefined') {
                    this.activeFilter[element.userData.mapToData.segment] = [];
                    this.activeFilter[element.userData.mapToData.segment].push(element.userData.mapToData.index);
                } else {
                    // If it already exists, simply push index data into the key
                    this.activeFilter[element.userData.mapToData.segment].push(element.userData.mapToData.index);
                }
            }
        });


        // #3 Create a new dataset, based on the "activeQuestion" dataset, which only contains data with our "activeFilter" content
        this.filterResult = this.store.state.data.activeQuestion.data;
        for (let i = 0; i < Object.values(this.activeFilter).length; i++) {
            let key = Object.keys(this.activeFilter)[i];

            this.filterResult = this.filterResult.filter((currentElement) => {
                let found = false;
                for (let j = 0; j < this.activeFilter[key].length; j++) {
                    if (currentElement[key] == this.activeFilter[key][j])
                        found = true
                }
                return found;
            });
        }
        this.store.dispatch("updateState", { parent: "data", key: "filterAmount", value: this.filterResult.length });


        // #4 Activate the corresponding lines by looping through the "filterResult"
        for (let index in this.filterResult) {
            let dataPoint = this.filterResult[index]['curves'];

            for (let i = 0; i < Object.values(this.activeFilter).length; i++) {
                let activeKey = Object.keys(this.activeFilter)[i];
                if (activeKey != 'Answer') {
                    let curveMesh = dataPoint[activeKey];
                    if (this.store.state.colors.flatLines) {
                        curveMesh.material.uniforms.uColor.value = new THREE.Color(this.store.state.colors.highlightColor);
                    } else {
                        curveMesh.material.color = new THREE.Color(this.store.state.colors.highlightColor);
                        curveMesh.material.emissive = new THREE.Color(this.store.state.colors.highlightColor);
                    }
                    curveMesh.material.uniforms.uOpacity.value = 1;
                    curveMesh.visible = true;

                    // Animate
                    curveMesh.material.uniforms.time.value = 0;
                    gsap.to(curveMesh.material.uniforms.time, {
                        duration: random(.5, 1),
                        value: 1,
                    });
                    curveMesh.userData.activeFilter = true;
                }
            }
        }
    }





    updateMarkers() {
        if (this.store.state.data.filterObjects.length < 1 || typeof this.activeFilter == undefined) {
            for (let i = 0; i < this.dataMarkers.length; i++) {
                // Make marker original size again
                let marker = this.dataMarkers[i];
                if (!this.store.state.colors.flatDesign)
                    marker.material.emissive = new THREE.Color('#000000')
                let originalSize = marker.userData.originalSize;

                // Animated size
                gsap.to(marker.scale, {
                    ease: Quad.easeOut,
                    duration: 1,
                    x: originalSize,
                    y: originalSize,
                    z: originalSize,
                });

                // Hide percentage distributions on the labels
                let span = marker.userData.elem.querySelector('span');
                span.style.transitionDelay = '0s';
                span.classList.remove('active');
            }
            return;
        }


        // #1 Loop over all data markers and make their size based on the reflected values of the filtered dataset
        for (let i = 0; i < this.dataMarkers.length; i++) {
            let marker = this.dataMarkers[i];
            let mapTo = marker.userData.mapToData;

            // Determine the amount of instances in the "filterResult" array that matches this markers data properties
            let instancesFound = this.filterResult.filter((obj) => obj[mapTo.segment] == mapTo.index).length;
            let validResults = this.filterResult.length;

            // Determine average value
            let average;
            if (this.filterResult.length <= 0) {
                average = 0;
            } else {
                average = instancesFound / validResults;
            }
            let objectSize = map(average, 0, 1, 0, 1.5);

            // Animate the number to the next value
            let span = marker.userData.elem.querySelector('span');
            let counter = { var: parseFloat(span.textContent) };
            gsap.to(counter, {
                var: average * 100,
                duration: .5,
                onUpdate: () => {
                    let nwc = counter.var.numberFormat(1);
                    span.textContent = nwc + '%';
                },
                ease: Quad.easeOut
            });
            span.style.transitionDelay = i * 0.01 + 's';
            span.classList.add('active');

            // Animated size
            gsap.to(marker.scale, {
                ease: Quad.easeOut,
                duration: 1,
                x: objectSize,
                y: objectSize,
                z: objectSize,
            });
        }

    }



    controlInteraction_started() {
        this.interface.disableLabels();
    }

    controlInteraction_ended() {
        this.interface.enableLabels();
    }
}


Number.prototype.numberFormat = function (decimals, dec_point, thousands_sep) {
    dec_point = typeof dec_point !== 'undefined' ? dec_point : '.';
    thousands_sep = typeof thousands_sep !== 'undefined' ? thousands_sep : '';

    var parts = this.toFixed(decimals).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

    return parts.join(dec_point);
}