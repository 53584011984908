<template>
  <!-- <div id="nav" v-show="$store.state.UI.visible">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <router-view />

  <div id="version" v-show="$store.state.UI.visible">Version {{ store.getters.appVersion }}</div>

  <!-- THEME SWITCHER-->
  <div id="themeToggleContainer" v-show="$store.state.UI.visible">
    <input type="checkbox" class="themeToggle" id="themeToggle" @click="toggleTheme">
    <label for="themeToggle" class="themeToggleComponent">
      <i class="fas fa-moon"><img :src="$store.getters.getPublicPath + 'UI/sun-solid.svg'" alt="Dark mode" /></i>
      <i class="fas fa-sun"><img :src="$store.getters.getPublicPath + 'UI/moon-solid.svg'" alt="Light mode" /></i>
      <span class="ball"></span>
    </label>
  </div>

  <!-- TRANSMISSION LOGO -->
  <div id="transmissionLogo">
    <a href="https://transmissionagency.com/" target="_blank">
      <img :src="$store.getters.getPublicPath + 'UI/TRANSMISSION-LOGO.svg'" alt="Transmission London" />
    </a>
  </div>

</template>

<script>
import { useStore } from "vuex";
import { consoleLog, consoleError, consoleSticky } from "@/utils/utils.js";

export default {
  setup() {
    const store = useStore();

    // Global variables and functions
    global.storeReference = store;
    global.log = consoleLog;
    global.error = consoleError;
    global.sticky = consoleSticky;

    // Set theme
    document.documentElement.className = store.state.UI.theme;

    // Toggle between light and dark themes
    function toggleTheme() {
      document.documentElement.className == "light" ? (document.documentElement.className = "dark") : (document.documentElement.className = "light");

      store.dispatch("updateState", {
        parent: "UI",
        key: "theme",
        value: document.documentElement.className,
      });
    }

    return { store, toggleTheme };
  },
};
</script>


<style lang="scss">
@import "@/assets/styles/theming.scss";

#nav {
  position: absolute;
  bottom: 35px;
  left: 30px;
  user-select: none;

  a {
    transition: color 0.5s;
    @include theme(color, linkColor);

    &.router-link-exact-active {
      font-weight: bold;
    }
  }
}

#version {
  transition: color 0.5s;
  position: absolute;
  bottom: 20px;
  left: 50px;
  z-index: 10;
  @include theme(color, secondaryTextColor);
  font-size: 10px;
}


#transmissionLogo {
  position: absolute;
  bottom: 40px;
  right: 30px;

  img {
    width: 200px;
  }
}
</style>
