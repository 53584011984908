<!--
# Example usage:
<Accordion title="Accordion Title" open="true"> </Accordian>
-->


<template>
  <div class="UI_accordion">
    <div class="UI_accordion_header" @click="toggleAccordion">
      <div class="UI_accordion_header_icon" :class="openState == true ? 'open' : ''"></div>
      {{ title }}
    </div>
    <div class="UI_accordion_content" :style="{ height: accordion_height }">
      <div class="UI_accordion_slot">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-dom";

export default {
  props: ["title", "open"],
  setup(props) {
    let openState = ref(null);
    let accordion_height = ref(0);

    onMounted(() => {
      if (props.open == "true") {
        accordion_height.value = "auto";
        openState.value = true;
      } else {
        accordion_height.value = 0;
        openState.value = false;
      }
    });

    function toggleAccordion() {
      openState.value = !openState.value;
      if (accordion_height.value == 0) {
        accordion_height.value = "auto";
        openState.value = true;
      } else {
        accordion_height.value = 0;
        openState.value = false;
      }
    }

    return { toggleAccordion, openState, accordion_height };
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/theming.scss";
.UI_accordion {
  width: 100%;
  font-size: 10px;
  text-transform: uppercase;
}
.UI_accordion_header {
  @include theme(background-color, UI_accordion_headerColor);
  @include theme(color, UI_window_fontColor);

  padding: 10px;
  cursor: pointer;
  user-select: none;
  @include theme(border-top, UI_accordion_headerBorder, 1px solid);
  @include theme(border-bottom, UI_accordion_headerBorder, 1px solid);
}
.UI_accordion_header_icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: 10px;
  float: left;
}
.UI_accordion_header_icon:after {
  content: "▶";
}
.UI_accordion_header_icon.open:after {
  content: "▼";
}
.UI_accordion_content {
  height: 0;
  overflow: hidden;
}
.UI_accordion_slot {
  padding: 20px;
}
</style>