<!--
# Example usage:
<Dropdown :selected="$store.state.design.markerType" :bindTo="{ parent: 'design', key: 'markerType' }" :options="{ Spheres: 0, Objects: 1, Donuts: 3, None: 2 }" />
-->

<template>
  <div class="UI_label">{{ label }}</div>
  <div class="select-dropdown">
    <select @change="updateData">
      <option v-for="(option, index) in optionsArray" :key="index" :value="option.value" :selected="option.value === selected">{{ option.title }}</option>
    </select>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, toRef } from "vue";

export default {
  props: ["onComplete", "selected", "options", "bindTo", "label"],
  name: "Dropdown",

  setup(props) {
    const store = useStore();
    const options = toRef(props, "options");

    // Make the options array reactive to state changes by using a computed property. This way, we can change the content of the dropdown at any time
    let optionsArray = computed({
      get() {
        // Parse the options array. This allows both to just send over an array of data or key/values pairs which contain a title and value
        let oArray = [];
        if (!Array.isArray(options.value)) {
          // Object-based prop where key and value pairs contain both a title and the actual value
          for (let [key, value] of Object.entries(options.value)) {
            oArray.push({
              value: value,
              title: key,
            });
          }
        } else {
          // Standard array with name and option being the same
          for (let index in options.value) {
            oArray.push({
              value: options.value[index],
              title: options.value[index],
            });
          }
        }

        return oArray;
      },
      set(newValue) {
        options.value = newValue;
      },
    });

    // update VueX store data
    function updateData(event) {
      this.store.dispatch("updateState", {
        parent: props.bindTo.parent,
        key: props.bindTo.key,
        value: event.target.value,
      });

      // Trigger onComplete function (if present)
      if (props.onComplete != undefined) props.onComplete(event);
    }


    return { store, updateData, optionsArray };
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/theming.scss";
.select-dropdown select {
  user-select: none;

  appearance: none;
  padding: 10px 35px 10px 10px;
  font-size: 12px;

  background: transparent;
  color: #1f2023;
  border: 1px solid #1f2023;
  background-repeat: no-repeat;
  background-position: right 13px top 14px;
  background-size: 0.9375em 0.5625em;
  background-image: url("data:image/svg+xml;charset=uft8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 13'%3E%3Cpolyline fill='none' stroke='currentColor' stroke-width='3' points='1,1 11,11 21,1'/%3E%3C/svg%3E");

  width: 100%;
  @include theme(filter, UI_toolBar_svgIconColor);

  outline: none;
  cursor: pointer;
}
</style>