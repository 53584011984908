import * as THREE from 'three';
import glslify from 'glslify';

export default class CustomMaterial extends THREE.MeshStandardMaterial {

    constructor(opt) {
        super(opt);
        this.type = 'CustomMaterial';

        this.uniforms = THREE.UniformsUtils.merge([
            THREE.ShaderLib.standard.uniforms,
            {
                time: { type: 'f', value: 0 },
                uOpacity: { value: 1 },
            }
        ]);

        this.vertexShader = glslify(require('./custom-vertex.glsl').default);
        this.fragmentShader = glslify(require('./custom-fragment.glsl').default);
        // this.fragmentShader = THREE.ShaderChunk.meshphysical_frag;
    }
}