<template>
  <Window open="false" title="Export" width="420px" left="80px" top="30px" :icon="$store.getters.getPublicPath + 'UI/icon_export.svg'">
    <div id="UI_export">
      <div id="UI_export_heading">
        <h3 style="padding: 0 20px 0 20px">Export as image, scalable vector, video or 3D model.</h3>
        <h5>&#9432; <a href="#">Click here for a video demonstration of how export works</a>.</h5>
      </div>

      <div id="UI_export_options">
        <!-- EXPORT IMAGE -->
        <!-- --------------------------------------------------------------------------------- -->
        <!-- --------------------------------------------------------------------------------- -->
        <div id="UI_exportImage" class="UI_exportOption">
          <!-- ILLUSTRATION -->
          <div class="UI_export_illustrationContainer">
            <div class="UI_export_illustrationContainer_illustration">
              <div class="UI_export_illustrationContainer_illustration_svg">
                <img v-show="$store.state.export.imageFormat == 'PNG' || $store.state.export.imageFormat == 'JPG'" :src="$store.getters.getPublicPath + 'UI/illustration_exportImage.svg'" alt="" />
                <img v-show="$store.state.export.imageFormat == 'WEBM'" :src="$store.getters.getPublicPath + 'UI/illustration_exportVideo.svg'" alt="" />
                <img v-show="$store.state.export.imageFormat == 'OBJ'" :src="$store.getters.getPublicPath + 'UI/illustration_exportScene.svg'" alt="" />
                <img v-show="$store.state.export.imageFormat == 'GLTF'" :src="$store.getters.getPublicPath + 'UI/illustration_exportScene.svg'" alt="" />
              </div>
            </div>
            <div class="UI_export_illustrationContainer_icon">
              <img v-show="$store.state.export.imageFormat == 'PNG' || $store.state.export.imageFormat == 'JPG'" :src="$store.getters.getPublicPath + 'UI/icon_download_image.svg'" alt="" />
              <img v-show="$store.state.export.imageFormat == 'OBJ'" :src="$store.getters.getPublicPath + 'UI/icon_download_scene.svg'" alt="" />
              <img v-show="$store.state.export.imageFormat == 'WEBM'" :src="$store.getters.getPublicPath + 'UI/icon_download_video.svg'" alt="" />
              <img v-show="$store.state.export.imageFormat == 'GLTF'" :src="$store.getters.getPublicPath + 'UI/icon_download_scene.svg'" alt="" />
            </div>
          </div>

          <!-- HEADING -->
          <div class="UI_export_row UI_export_heading" v-show="$store.state.export.imageFormat == 'PNG' || $store.state.export.imageFormat == 'JPG'">High resolution static image</div>
          <div class="UI_export_row UI_export_heading" v-show="$store.state.export.imageFormat == 'OBJ'">3D model file, OBJ format</div>
          <div class="UI_export_row UI_export_heading" v-show="$store.state.export.imageFormat == 'GLTF'">3D model file, GLTF format</div>
          <div class="UI_export_row UI_export_heading" v-show="$store.state.export.imageFormat == 'WEBM'">Animated video file</div>

          <!-- FORMAT & TRANSPARENCY -->
          <div class="UI_export_row">
            <div class="UI_export_row_column">
              <div class="UI_export_label">File format</div>
              <Dropdown :selected="$store.state.export.imageFormat" :bindTo="{ parent: 'export', key: 'imageFormat' }" :options="['PNG', 'JPG', 'OBJ', 'GLTF', 'WEBM']" />
            </div>
            <div class="UI_export_row_column" v-show="$store.state.export.imageFormat == 'PNG'">
              <div class="UI_export_label" style="margin-bottom: 10px">Transparent background</div>
              <Toggle labelOn="Yes" labelOff="No" :bindTo="{ parent: 'export', key: 'transparentBackground' }" />
            </div>
          </div>

          <!-- IMAGE EXPORT -->
          <div class="UI_export_row" v-show="$store.state.export.imageFormat == 'PNG' || $store.state.export.imageFormat == 'JPG'">
            <div class="UI_export_row_column">
              <div class="UI_export_label">Image size</div>
              <SizeSelector :width="$store.state.export.width" :height="$store.state.export.height" />
            </div>
            <div class="UI_export_row_column UI_export_paragraphText">
              <span style="width: 185px; display: inline-block; margin-top: 33px">{{ sizeSelectorText }}</span>
            </div>
          </div>

          <div v-show="$store.state.export.imageFormat == 'WEBM'">
            <!-- Larger Text -->
            <div class="UI_export_row">
              <div class="UI_export_paragraphText" style="padding-right: 40px">Record the action on screen by pressing the start <i>recording button</i>. Once you have recorded enough footage, simply press the <i>stop recording</i> button.</div>
            </div>

            <!-- Pro tip smaller Text -->
            <div class="UI_export_row">
              <div class="UI_export_paragraphTextSmall" style="margin-top: -20px; padding-right: 40px">Pro tip: You can manipulate the camera and/or change any property of the graphic during the recording process and record the resulting changes as well. <u>Press the ESC key to hide or show the user interface.</u></div>
            </div>
          </div>

          <!-- OBJ EXPORT -->
          <div class="UI_export_row" v-show="$store.state.export.imageFormat == 'OBJ'">
            <div class="UI_export_paragraphText" style="width: 350px; padding-right: 30px; margin-bottom: 7px">Selecting this options allows you to export the entire scene in a format which can be processed in virtually any 3D-modelling and -animation software like Blender, Maya, Cinema 4D, 3DSMax, etc.</div>
          </div>

          <!-- GLTF EXPORT -->
          <div class="UI_export_row" v-show="$store.state.export.imageFormat == 'GLTF'">
            <div class="UI_export_paragraphText" style="width: 350px; padding-right: 30px; margin-bottom: 7px">Selecting this options allows you to export the entire scene in a format which can be processed in a compatible 3D-modelling and -animation software like Blender. The exported gLTF file is of the type model/gltf+json.</div>
          </div>

          <!-- EXPORT IMAGE BUTTON -->
          <div class="UI_export_row" v-show="showImageExportButton">
            <Button v-show="$store.state.export.imageFormat == 'PNG' || $store.state.export.imageFormat == 'JPG'" @click="exportImage" title="Export high resolution image" style="width: 100%; margin: 10px 55px 0 0" />
            <Button v-show="$store.state.export.imageFormat == 'OBJ'" @click="exportOBJ" title="Export 3D scene" style="width: 100%; margin: 10px 55px 0 0" />
            <Button v-show="$store.state.export.imageFormat == 'GLTF'" @click="exportGLTF" title="Export 3D scene" style="width: 100%; margin: 10px 55px 0 0" />
            <Button v-show="$store.state.export.imageFormat == 'WEBM'" :theme="recordButtonTheme" @click="toggleRecording" :title="recordButtonTitle" style="width: 100%; margin: 2px 55px 0 0" />
          </div>
        </div>
      </div>
    </div>
  </Window>
</template>

<script>
// Vue
import { useStore } from "vuex";
import { getCurrentInstance, onMounted } from "@vue/runtime-dom";

//
import ImageExporter from "@/utils/ImageExporter.js";
import SceneExporter from "@/utils/SceneExporter.js";
import OBJSceneExporter from "@/utils/OBJSceneExporter.js";
import CanvasRecorder from "@/utils/CanvasRecorder.js";
import { threeManager } from "@/views/DesignSystem.vue";

// UI components
import Window from "@/components/UI/components/window.vue";
import Dropdown from "@/components/UI/components/dropdown.vue";
import Toggle from "@/components/UI/components/toggle.vue";
import SizeSelector from "@/components/UI/components/sizeSelector.vue";
import Button from "@/components/UI/components/button.vue";
import { ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-dom";
export default {
  components: {
    Window,
    Dropdown,
    Toggle,
    SizeSelector,
    Button,
  },
  name: "ExportWindow",
  setup() {
    const store = useStore();

    // Image variables
    const sizeSelectorText = ref("The aspect ratio is based on the size of your browser window and can be altered by resizing it.");
    const showImageExportButton = ref(true);

    // Video variables
    const recordButtonTitle = ref("Start recording");
    const recordButtonTheme = ref("");
    let recordingActive = false;
    let canvasRecorder = undefined;
    let targetBitrate = 1500;

    onMounted(() => {
      // Mark window as mounted in the VueX store (so other components can subscribe and use it once its available)
      let componentName = getCurrentInstance().type.name;
      store.dispatch("UI_mountWindow", { window: componentName });
    });

    watch(
      () => store.state.export.width,
      () => {
        let totalResolution = store.state.export.width * store.state.export.height;
        // If the resolution is fine
        if (totalResolution < 268435456) {
          // If we haven't already set the button to be visible
          if (!showImageExportButton.value) {
            showImageExportButton.value = true;
            sizeSelectorText.value = "Image size is based on the size of your browser window and can be altered by resizing it.";
          }
        } else {
          // If the resolution exceeds the memory limit
          if (showImageExportButton.value) {
            showImageExportButton.value = false;
            sizeSelectorText.value = "The desired resolution exceeds the memory limits of your browser. Please select a lower resolution.";
          }
        }
      }
    );

    function exportImage() {
      let imageExporter = new ImageExporter({});
      imageExporter.export();
      imageExporter = {};
    }

    function exportOBJ() {
      let sceneExporter = new OBJSceneExporter();
      sceneExporter.export();
      sceneExporter = {};
    }

    function exportGLTF() {
      let sceneExporter = new SceneExporter();
      sceneExporter.export();
      sceneExporter = {};
    }

    function toggleRecording() {
      recordingActive = !recordingActive;

      if (recordingActive) {
        // Start recording
        global.log("Recording in <i>" + store.state.export.videoFormat + "</i> video format at <i>" + targetBitrate / 100 + " Mbps</i> bitrate.");
        recordButtonTitle.value = "Stop recording";
        recordButtonTheme.value = "recording";

        canvasRecorder = new CanvasRecorder(global.threeManager.renderer.domElement, targetBitrate * 1000000);
        canvasRecorder.start();
      } else {
        // Stop recording
        recordButtonTitle.value = "Start recording";
        recordButtonTheme.value = "";
        canvasRecorder.stop();
        canvasRecorder.save("Animated Video Recording.webm");
      }
    }

    return { store, exportImage, toggleRecording, exportOBJ, exportGLTF, exportImage, sizeSelectorText, showImageExportButton, recordButtonTitle, recordButtonTheme };
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/theming.scss";

#UI_export {
  padding: 20px 0 0 0;
  font-size: 12px;

  a {
    @include theme(color, linkColor);
  }

  a:visited {
    @include theme(color, linkColor);
  }
}

#UI_export_options {
  display: flex;
  justify-content: center;
  margin-top: -15px;
}

#UI_export_heading {
  text-align: center;

  h3 {
    margin: 0 0 15px 0;
    padding: 0;
    font-size: 24px;
    font-weight: normal;
  }

  h5 {
    margin: 0 0 20px 0;
    padding: 0;
    font-weight: normal;
    font-size: 12px;
  }

  width: 100%;
}

.UI_export_heading {
  font-size: 16px;
  display: block;
}

.UI_exportOption {
  width: 100%;
  flex-wrap: wrap;
  @include theme(background-color, UI_window_lightBackground);
  margin-top: 20px;
}

.UI_export_illustrationContainer {
  width: 100%;
  height: 220px;
  @include theme(background-color, UI_toolBar_iconActiveBackgroundColor);
  user-select: none;
}

.UI_export_illustrationContainer_icon {
  @include theme(background-color, UI_toolBar_background);
  border-radius: 50%;
  position: absolute;
  width: 70px;
  height: 70px;
  margin: 10px 0 0 20px;

  img {
    @include theme(filter, UI_toolBar_svgIconColor);
    padding: 11px;
    width: 70%;
  }
}

.UI_export_illustrationContainer_illustration {
  position: absolute;
  height: 195px;
  width: 380px;
  margin: 25px 0 0 40px;
  background: #a6a6a6;

  img {
    height: 160px;
    margin: 35px 0 0 100px;
  }
}

.UI_export_row {
  display: flex;
  margin: 0px 0 30px 40px;
}

.UI_export_row_column {
  width: 170px;
  margin-right: 20px;
}

.UI_export_heading {
  font-size: 18px;
  margin-top: 30px;
}

.UI_export_label {
  font-size: 12px;
  margin-bottom: 7px;
}

.UI_export_paragraphText {
  line-height: 1.8;
}

.UI_export_paragraphTextSmall {
  line-height: 1.6;
  font-size: 10px;
}
</style>