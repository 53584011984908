<template>
  <div class="UI_window" ref="windowRef" v-show="windowVisible">
    <div class="UI_window_header" ref="windowHeader">
      {{ title }}
      <span class="UI_closeIcon" @click="close">×</span>
    </div>

    <div class="UI_window_content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { onMounted, ref, toRef } from "@vue/runtime-dom";
import { getCurrentInstance } from "vue";
export default {
  name: "Window",
  props: ["title", "width", "height", "top", "left", "bottom", "right", "maxHeight", "icon", "open", "resizable"],
  setup(props) {
    // VueX store
    const store = useStore();

    // Get reference to current component
    const { proxy } = getCurrentInstance();
    // Expose public methods
    Object.assign(proxy, {
      show,
      close,
      toggle,
      toFront,
      isOpen,
    });

    // Props
    const width = toRef(props, "width");
    const height = toRef(props, "height");
    const top = toRef(props, "top");
    const left = toRef(props, "left");
    const bottom = toRef(props, "bottom");
    const right = toRef(props, "right");
    const maxHeight = toRef(props, "maxHeight");
    const icon = toRef(props, "icon");
    const open = toRef(props, "open");
    const resizable = toRef(props, "resizable");

    // Interface data object
    let interfaceData = {
      visible: true,
      windowPosition: { x: -1, y: -1 },
      width: width.value,
      initialClick: { x: -1, y: -1 },
      headIsClicked: false,
    };

    // References to the window and window header and window visibility
    let windowRef = ref(null);
    let windowHeader = ref(null);
    let windowVisible = ref(false);
    let windowIconContainer;

    // Once mounted...
    onMounted(() => {
      // Set properties of window
      setWindowProperties();

      // Register icon for the window
      registerWindowIcon();

      // Attach event handlers for dragging the window around
      attachEventHandlers();
    });

    // Register icon for the window
    function registerWindowIcon() {
      // Get references to DOM elements
      let UI = document.getElementById("UI");

      // Check for iconContainer window - if one does not exist, create it
      let iconContainer = getIconContainerWindow();

      // Create icon container
      windowIconContainer = document.createElement("div");
      if (windowVisible.value) {
        windowIconContainer.setAttribute("class", "UI_windowIconContainer UI_windowIcon_active");
      } else {
        windowIconContainer.setAttribute("class", "UI_windowIconContainer");
      }
      windowIconContainer.setAttribute("title", props.title);
      iconContainer.appendChild(windowIconContainer);

      // The actual icon inside
      if (icon.value != undefined) {
        // If there is an icon prop, inject as image
        let windowIcon = document.createElement("img");
        windowIcon.setAttribute("class", "UI_windowIcon");
        windowIcon.setAttribute("src", icon.value);
        windowIconContainer.appendChild(windowIcon);
      } else {
        // If there is no icon prop, use the first character of the title as the icon
        let windowIcon = document.createElement("div");
        windowIcon.setAttribute("class", "UI_windowIconChar");
        windowIcon.innerText = props.title.charAt(0);
        windowIconContainer.appendChild(windowIcon);
      }

      // Click behavior
      windowIconContainer.addEventListener("mouseup", () => {
        toggle();
      });
    }

    function getIconContainerWindow() {
      // Get reference to DOM element
      let iconContainer = document.getElementById("UI_iconContainer");

      // If there is not yet an iconContainer DOM element, create one
      if (iconContainer == null) {
        iconContainer = document.createElement("div");
        iconContainer.setAttribute("id", "UI_iconContainer");
        UI.appendChild(iconContainer);
      }

      // Return reference
      return iconContainer;
    }

    // Set properties of window
    function setWindowProperties() {
      if (open.value != undefined) {
        if (open.value.toLowerCase() == "true") {
          windowVisible.value = true;
        }
      }
      if (left.value) {
        windowRef.value.style.left = left.value;
      }
      if (right.value) {
        windowRef.value.style.right = right.value;
      }
      if (top.value) {
        windowRef.value.style.top = top.value;
      }
      if (bottom.value) {
        windowRef.value.style.bottom = bottom.value;
      }
      if (width.value) {
        windowRef.value.style.width = width.value;
      } else {
        windowRef.value.style.width = 300;
      }
      if (height.value) {
        windowRef.value.style.maxHeight = height.value;
      }
      if (maxHeight.value) {
        windowRef.value.style.maxHeight = maxHeight.value;
      }
      if (resizable.value) {
        windowRef.value.style.resize = "both";
      }
    }

    // Attach event handlers to the window
    function attachEventHandlers() {
      // Focus window by clicking it and bring it to the front of the stack
      windowRef.value.addEventListener("mousedown", (e) => {
        //bringWindowToFront();
        toFront();
      });

      // Click on window header
      windowHeader.value.addEventListener("mousedown", (e) => {
        // Store initial click and mark head area as clicked
        e.preventDefault();
        interfaceData.initialClick.x = e.clientX;
        interfaceData.initialClick.y = e.clientY;
        interfaceData.headIsClicked = true;
      });

      // Dragging window
      windowHeader.value.addEventListener("mousemove", (e) => {
        e.preventDefault();
      });

      // End of drag behavior
      document.body.addEventListener("mousemove", (e) => {
        if (interfaceData.headIsClicked) {
          let windowOffset = {
            left: windowRef.value.offsetLeft,
            top: windowRef.value.offsetTop,
          };
          interfaceData.windowPosition = {
            x: windowOffset.left - (interfaceData.initialClick.x - e.clientX),
            y: windowOffset.top - (interfaceData.initialClick.y - e.clientY),
          };
          interfaceData.initialClick.x = e.clientX;
          interfaceData.initialClick.y = e.clientY;

          windowRef.value.style.left = interfaceData.windowPosition.x + "px";
          windowRef.value.style.top = interfaceData.windowPosition.y + "px";
          windowRef.value.style.right = null;
          windowRef.value.style.bottom = null;
        }
      });
      document.body.addEventListener("mouseup", (e) => {
        interfaceData.headIsClicked = false;
      });
      document.addEventListener("mouseleave", () => {
        interfaceData.headIsClicked = false;
      });
    }

    // Show the window
    function show() {
      interfaceData.visible = true;
      windowVisible.value = true;
      windowIconContainer.classList.add("UI_windowIcon_active");
      toFront();
    }

    // Close the window
    function close() {
      interfaceData.visible = false;
      windowVisible.value = false;
      windowIconContainer.classList.remove("UI_windowIcon_active");
    }

    // Togle window state
    function toggle() {
      if (windowVisible.value) {
        close();
      } else {
        show();
      }
    }

    // Send window to front
    function toFront() {
      // Update store z-index info by increasing it by 1
      store.dispatch("updateState", {
        parent: "UI",
        key: "windowZIndex",
        value: store.state.UI.windowZIndex + 1,
      });

      // Set the value of the current window to that value
      windowRef.value.style.zIndex = store.state.UI.windowZIndex;
    }

    // Return if window is open or not
    function isOpen() {
      return windowVisible.value;
    }

    return {
      windowRef,
      windowHeader,
      close,
      windowVisible,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/theming.scss";

.UI_window {
  transition: background-color 0.5s;
  position: absolute;

  @include theme(color, UI_window_fontColor);
  border-radius: 10px;
  z-index: 1000;
  // resize: both;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 140px;
  min-height: 100px;
  font-size: 12px;
}
/* Add frosted glass effect for browser that support it */
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .UI_window {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    @include theme(background-color, UI_window_backgroundColor_blurred);
  }
}
/* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .UI_window {
    @include theme(background-color, UI_window_backgroundColor_notBlurred);
  }
}

.UI_window_header {
  transition: background-color 0.5s;
  @include theme(background-color, UI_window_headerColor);
  @include theme(color, UI_window_fontColor);
  padding: 10px 10px 10px 20px;
  cursor: move;
  user-select: none;
  border-radius: 5px;
  font-size: 10px;
  text-transform: uppercase;

  .UI_closeIcon {
    position: absolute;
    right: 0;
    font-weight: bold;
    cursor: pointer;
    padding: 10px;
    margin: -18px 0 0 0;
    font-size: 20px;
    &:hover {
      opacity: 0.5;
    }
  }
}
</style>