import { OBJExporter } from 'three/examples/jsm/exporters/OBJExporter.js';

export default class SceneExporter {
    constructor() {
        this.threeManager = global.threeManager;
    }


    export() {
        this.objExporter = new OBJExporter();
        const result = this.objExporter.parse(this.threeManager.scene);
        this.saveString(result, '3D Scene Export.obj');
    }



    saveString(text, filename) {
        this.save(new Blob([text], { type: 'text/plain' }), filename);
    }


    save(blob, filename) {
        const link = document.createElement('a');
        link.style.display = 'none';
        document.body.appendChild(link);

        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    }
}
