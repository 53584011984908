<template>
  <div id="UI" v-show="$store.state.UI.visible">
    <component v-for="(component, index) in store.state.UI.windows" :key="index" :is="component" />
  </div>
</template>

<script>
// Vue
import { onMounted } from "@vue/runtime-dom";
import { useStore } from "vuex";

// UI components
import DesignParametersWindow from "@/components/UI/windows/design.vue";
import ExportWindow from "@/components/UI/windows/export.vue";
import PerformanceWindow from "@/components/UI/windows/performance.vue";
import ConsoleWindow from "@/components/UI/windows/console.vue";

//
import "@melloware/coloris/dist/coloris.css";
import Coloris from "@melloware/coloris";

// Toggle UI visibility state
function toggleUI_visibility(e) {
  if (e.key == "Escape") {
    global.storeReference.dispatch("UI_toggleVisibility");
  }
}

export default {
  name: "UI",
  methods: {
    destroy: function () {
      const store = useStore();
      document.body.removeEventListener("keyup", toggleUI_visibility);
      store.dispatch("updateState", { parent: "UI", key: "windows", value: [] });
      store.dispatch("updateState", { parent: "UI", key: "mountedWindows", value: [] });
    },
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      // Register window to vueX store
      // store.dispatch("UI_registerWindow", { window: DesignParametersWindow });
      store.dispatch("UI_registerWindow", { window: ExportWindow });
      store.dispatch("UI_registerWindow", { window: PerformanceWindow });
      store.dispatch("UI_registerWindow", { window: ConsoleWindow });

      // Initialize coloris color-picker component
      Coloris.init();

      // Add ESC-toggle functionality
      attachEventHandlers();
    });

    function attachEventHandlers() {
      document.body.addEventListener("keyup", toggleUI_visibility);
    }

    // RETURN ---------------------------------------------------------------------------------------------
    return { store };
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/theming.scss";

#UI_iconContainer {
  position: absolute;
  top: 30px;
  left: 20px;
}

.UI_windowIconContainer {
  transition: 0.5s;
  width: 35px;
  height: 35px;
  @include theme(border, UI_toolBar_border, 1px solid);
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 5px;
  @include theme(background-color, UI_toolBar_background);

  &:hover {
    @include theme(border, UI_toolBar_borderHover, 1px solid);

    .UI_windowIconChar {
      @include theme(color, UI_toolBar_iconColorHover);
    }
  }

  img {
    @include theme(filter, UI_toolBar_svgIconColor);
  }

  .UI_windowIcon {
    padding: 7px;
    width: 22px !important;
  }

  .UI_windowIconChar {
    font-size: 18px;
    font-weight: bold;
    @include theme(color, UI_toolBar_iconColor);
    text-align: center;
    margin-top: 8px;
  }
}

.UI_windowIcon_active {
  @include theme(background-color, UI_toolBar_iconActiveBackgroundColor);

  img {
    @include theme(filter, UI_toolBar_svgActiveIconColor);
  }
}

#UI_themeSwitcher {
  position: absolute;
  color: #fff;
  bottom: 15px;
  right: 20px;
  cursor: pointer;
  width: 40px;

  img {
    padding: 5px;
    @include theme(filter, UI_toolBar_svgIconColor);
    transition: 0.5s;

    &:hover {
      transition: 0.5s;
      transform: rotate(180deg);
    }
  }
}

.UI_label {
  font-size: 10px;
  margin-bottom: 10px;
}
</style>