<!--
# Example usage:
<SizeSelector :width="$store.state.export.width" :height="$store.state.export.height" />
-->

<template>
  <div class="UI_sizeSelector_label UI_sizeSelector_labelLeft">W</div>
  <input type="number" autocomplete="off" id="UI_sizeSelector_width" class="UI_sizeSelector" :value="width" @input="updateData" />
  <div class="UI_sizeSelector_label UI_sizeSelector_labelRight">px</div>
  <br />
  <div class="UI_sizeSelector_label UI_sizeSelector_labelLeft">H</div>
  <input type="number" autocomplete="off" id="UI_sizeSelector_height" class="UI_sizeSelector" :value="height" @input="updateData" />
  <div class="UI_sizeSelector_label UI_sizeSelector_labelRight">px</div>
  <img class="UI_sizeSelector_chainIcon" :src="$store.getters.getPublicPath + 'UI/icon_imageSizeChain.svg'" alt="" />
</template>

<script>
import { useStore } from "vuex";

export default {
  props: ["width", "height"],
  name: "Input",
  setup() {
    const store = useStore();

    // Determine new size in respect to aspectRatio and update VueX store data
    function updateData(event) {
      let THREE_canvas = document.getElementById("threeWebGL");

      if (event.target.value > 0) {
        if (event.target.id == "UI_sizeSelector_width") {
          let aspectRatio = THREE_canvas.height / THREE_canvas.width;
          this.store.dispatch("updateState", { parent: "export", key: "width", value: Math.round(event.target.value) });
          this.store.dispatch("updateState", { parent: "export", key: "height", value: Math.round(event.target.value * aspectRatio) });
        }
        if (event.target.id == "UI_sizeSelector_height") {
          let aspectRatio = THREE_canvas.width / THREE_canvas.height;
          this.store.dispatch("updateState", { parent: "export", key: "width", value: Math.round(event.target.value * aspectRatio) });
          this.store.dispatch("updateState", { parent: "export", key: "height", value: Math.round(event.target.value) });
        }
      }
    }

    return { store, updateData };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/theming.scss";

.UI_sizeSelector {
  padding: 10px;
  @include theme(color, textColor);
  @include theme(border, textColor, 1px solid);
  background: transparent;
  width: 40px;
  margin-bottom: 10px;
}
.UI_sizeSelector:focus {
  outline: none;
}
.UI_sizeSelector_label {
  display: inline-block;
}
.UI_sizeSelector_labelLeft {
  margin-right: 5px;
}
.UI_sizeSelector_labelRight {
  margin-left: 5px;
}
.UI_sizeSelector_chainIcon {
  height: 49px;
  position: absolute;
  margin-top: -28px;
  margin-left: 10px;
  @include theme(filter, UI_toolBar_svgIconColor);
}

// Hide arrows
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>