<template>
  <Window open="false" title="Design properties" width="420px" left="80px" top="30px" maxHeight="400px" resizable="true" :icon="$store.getters.getPublicPath + 'UI/icon_design.svg'">

    <Accordion title="Shading options" open="true">
      <div class="UI_design_row">
        <div class="UI_design_row_column">
          <div class="UI_label">Flat design</div>
          <Toggle labelOn="Yes" labelOff="No" :bindTo="{ parent: 'colors', key: 'flatDesign' }" />
        </div>
        <div class="UI_design_row_column">
          <div v-show="$store.state.colors.flatDesign == false">
            <div class="UI_label">Flat lines</div>
            <Toggle labelOn="Yes" labelOff="No" :bindTo="{ parent: 'colors', key: 'flatLines' }" />
          </div>
        </div>
      </div>
    </Accordion>

  </Window>
</template>

<script>
// Vue
import { useStore } from "vuex";
import { getCurrentInstance, onMounted } from '@vue/runtime-dom';

// UI components
import Window from "@/components/UI/components/window.vue";
import Accordion from "@/components/UI/components/accordion.vue";
import Toggle from "@/components/UI/components/toggle.vue";

export default {
  components: {
    Window,
    Accordion,
    Toggle,
  },
  name: 'DesignParametersWindow',
  setup() {
    const store = useStore();

    onMounted(() => {
      // Mark window as mounted in the VueX store (so other components can subscribe and use it once its available)
      let componentName = getCurrentInstance().type.name;
      store.dispatch("UI_mountWindow", { window: componentName });
    });

    return { store };
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/theming.scss";

.UI_design_row {
  display: flex;
  // width: 100%;
  font-size: 12px;
}

.UI_design_row_column {
  flex-basis: 100%;
  // width: 100%;
}
</style>